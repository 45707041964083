import { createTheme } from '@mui/material/styles';
import { red, blue, yellow } from '@mui/material/colors';

import type {} from '@mui/x-date-pickers/themeAugmentation';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(0, 112, 69)',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    gas: {
      main: blue[700]
    },
    electricity: {
      main: yellow[700]
    },
  } 
});

declare module '@mui/material/styles' {
  interface Palette {
    gas: Palette['primary'];
    electricity: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    gas?: PaletteOptions['primary'];
    electricity?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/LinearProgress' {
  interface LinearProgressPropsColorOverrides {
    gas: true;
    electricity: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsColorOverrides {
    gas: true;
    electricity: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconColorOverrides {
    gas: true;
    electricity: true;
  }
}

export default theme;