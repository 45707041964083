import { TextField, FormControl, Button, MenuItem, InputLabel, Table, TableHead, TableRow, TableCell, Stack, Select, TableBody, Typography, Paper } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { DnosService, DnoStatsModel, LandTypeEnum } from '../api/cl-client';
import { useQuery } from 'react-query';
import { getKeys } from '../utils/typescript';
import { useForm, useFieldArray } from 'react-hook-form';

interface AssetFormLandProps {
}

interface EmbodiedCarbonDetail {
    name?: string;
    co2Grams?: number;
    lifetimeYears?: number;
}

interface LandFormData {
    landRegistrationNumber: string;
    landAreaSizeM2: number;
    floorAreaSizeM2: number;
    originalLandType: LandTypeEnum;
    dnoId: number;
    epcRatingTarget: string;
    embodiedCarbon?: EmbodiedCarbonDetail[];
}

const embodiedCarbonDefault: EmbodiedCarbonDetail = {
    name: undefined,
    co2Grams: undefined,
    lifetimeYears: undefined
}

interface Result {
    landUseOffsetPerYear: number;
    embodiedCarbonPerYear: number;
    propertyUseCarbonPerYear: number;
    totalCarbonPerYear: number;
}

const epcTargates = ['A++', 'A+', 'A', 'B', 'C']

const AssetFormLand: FC<AssetFormLandProps> = () => {
    const [ result, setResult ] = useState<Result>();
    const [ dnoList, setDnoList ] = useState<Partial<DnoStatsModel>[]>([]);

    const { data: dnoData } = useQuery(["carbonIntensity"], () => DnosService.readDnoStatsApiDnosstatsGet());

    useEffect(() => {
        // console.log('CarbonIntesnityUpdated', dnoData)
        if (dnoData) {
            const dnoItems = Array.from(new Set(dnoData.map<string>(d => (JSON.stringify({dnoId: d.dnoId, regionShortName: d.regionShortName}))))).map(d => JSON.parse(d));
            setDnoList(dnoItems);
        } else {
            setDnoList([])
        }
    }, [dnoData])

    // const { mutate, isLoading: isLoadingMutation, isSuccess: isSuccessMutation } = useMutation(AssetsService.postAssetApiAssetsPost);

    const { control, register, handleSubmit } = useForm<LandFormData>();

    const { fields, append, remove } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "embodiedCarbon", // unique name for your Field Array
    });

    // CO₂ per m² per year find good source
    const landCarbonImpact: {[key in LandTypeEnum]: number} = {
        [LandTypeEnum.SETTLEMENT]: 0,
        [LandTypeEnum.CROPLAND]: 0,
        [LandTypeEnum.GRASSLAND]: 5,
        [LandTypeEnum.FOREST]: 10,
        [LandTypeEnum.WETLANDS]: 10,
        [LandTypeEnum.OTHER_LAND]: 10,
    }    

    const regionMenuItems = useMemo(
        () => dnoList.map(dno => <MenuItem value={dno.dnoId}>{dno.regionShortName}</MenuItem>), 
        [dnoList]);

        
    const epcMenuItems = useMemo(
        () => epcTargates.map(epc => <MenuItem value={epc}>{epc}</MenuItem>), 
        []);

    const resultsView = useMemo(() => (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Value</TableCell>
                    <TableCell>Unit</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {result ? getKeys(result).map(key => (
                <TableRow>
                    <TableCell>{key}</TableCell>
                    <TableCell>{result ? result[key] / 1e6 : null}</TableCell>
                    <TableCell>tCO₂/year</TableCell>
                </TableRow>
                )) : null}
            </TableBody>
        </Table>), [result])

    const onSubmit = (data: LandFormData) => {
        // console.log('onSubmit', data)

        const dnoRow = dnoData?.find(dno => (dno.dnoId === data.dnoId) && (dno.currentEnergyRating === data.epcRatingTarget));
        // console.log('dnoRow', dnoRow)

        const landUseOffsetPerYear = data.landAreaSizeM2 * landCarbonImpact[data.originalLandType]
        const embodiedCarbonPerYear = data.embodiedCarbon ? data.embodiedCarbon.reduce((ecTotal, ec) => ec.co2Grams && ec.lifetimeYears ? (ec.co2Grams / ec?.lifetimeYears) : 0, 0) : 0;
        
        const energyConsumptionPerM2 = dnoRow ? (dnoRow.energyConsumptionCurrentAvg ? dnoRow.energyConsumptionCurrentAvg : 0) : 0;
        // console.log('energyConsumptionPerM2', energyConsumptionPerM2)
        const carbonIntensity = dnoRow ? (dnoRow.dnoCi ? dnoRow.dnoCi : 0) : 0;
        // console.log('carbonIntensity', carbonIntensity)
        const propertyUseCarbon = data.floorAreaSizeM2 * energyConsumptionPerM2 * carbonIntensity
        const carbonPerYear = landUseOffsetPerYear + embodiedCarbonPerYear + propertyUseCarbon;

        setResult({
            landUseOffsetPerYear,
            embodiedCarbonPerYear,
            propertyUseCarbonPerYear: propertyUseCarbon,
            totalCarbonPerYear: carbonPerYear
        })
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={4} direction="column">
                <Stack spacing={2} direction="row" align-conten="baseline" display="none">
                    <TextField 
                        label="Land registration number"
                        variant="standard" 
                        fullWidth
                        {...register('landRegistrationNumber')}/>
                    <Button>Find</Button>
                </Stack>
                
                <Paper sx={{padding: 1}}>
                    <Typography variant="h6">Location and size</Typography>
                    <TextField 
                        label="Size of the land your are developing on in m²"
                        variant="standard" 
                        type="number"
                        fullWidth
                        {...register('landAreaSizeM2')}/>
                    <TextField 
                        label="Total floorspace of the building in m²"
                        variant="standard" 
                        type="number"
                        fullWidth
                        {...register('floorAreaSizeM2')}/>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="region-select-label">Region that you are developing in</InputLabel>
                        <Select labelId='region-select-label' {...register('dnoId')}>
                            {regionMenuItems}
                        </Select>
                    </FormControl>
                </Paper>
                    
                <Paper sx={{padding: 1}}>
                    <Typography variant="h6">Land use settings</Typography>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="land-type-select-label">Land type that you are developing on</InputLabel>
                        <Select labelId='land-type-select-label' {...register('originalLandType')}>
                            <MenuItem value={LandTypeEnum.SETTLEMENT}>Settlement</MenuItem>
                            <MenuItem value={LandTypeEnum.FOREST}>Forest</MenuItem>
                            <MenuItem value={LandTypeEnum.GRASSLAND}>Grassland</MenuItem>
                            <MenuItem value={LandTypeEnum.CROPLAND}>Cropland</MenuItem>
                            <MenuItem value={LandTypeEnum.WETLANDS}>Wetlands</MenuItem>
                            <MenuItem value={LandTypeEnum.OTHER_LAND}>Other land</MenuItem>
                        </Select>
                    </FormControl>
                </Paper>

                <Paper sx={{padding: 1}}>
                    <Typography variant="h6">Energetic targets of development</Typography>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="epc-select-label">Target EPC rating of the development</InputLabel>
                        <Select labelId='epc-select-label' {...register('epcRatingTarget')}>
                            {epcMenuItems}
                        </Select>
                    </FormControl>
                </Paper>
                
                <Paper sx={{padding: 1}}>
                <Typography variant="h6">Embodied carbon of development</Typography>
                {fields.map((field, index) => (
                    <Stack key={field.id} spacing={2} direction="row" align-conten="baseline">
                        <TextField
                            variant='standard'
                            label="Name"
                            key={field.id} // important to include key with field's id
                            {...register(`embodiedCarbon.${index}.name` as const)} 
                        />
                        <TextField
                            variant='standard'
                            label="CO₂ in grams"
                            key={field.id} // important to include key with field's id
                            {...register(`embodiedCarbon.${index}.co2Grams` as const)} 
                        />
                        <TextField
                            variant='standard'
                            label="Lifetime in years"
                            key={field.id} // important to include key with field's id
                            {...register(`embodiedCarbon.${index}.lifetimeYears` as const)} 
                        />
                        <Button onClick={() => remove(index)}>DELETE</Button>
                    </Stack>
                ))}
                <Button onClick={() => append(embodiedCarbonDefault)}>APPEND</Button>
                </Paper>
                
                <Button type='submit'>SUBMIT</Button>
                </Stack>
            </form>
            {resultsView}
        </>
    );
}

export default AssetFormLand;