import { FC, forwardRef, MouseEventHandler, useMemo } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';


import TrendingDown from '@mui/icons-material/TrendingDown';
import TrendingFlat from '@mui/icons-material/TrendingFlat';
import TrendingUp from '@mui/icons-material/TrendingUp';
import { epcRatingColors } from '../../utils/epc';

export interface GroupBounds {
    upper?: number;
    lower?: number;
}

enum EpcRatings {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E',
    F = 'F',
    G = 'G',
}

export type EpcGroupBounds = Record<EpcRatings, GroupBounds>

export function getRating(ratings: EpcGroupBounds, value: number) {
    const rating =  Object.entries(ratings).find(([key, bounds]) => {
        if (bounds.upper && bounds.upper > value && bounds.lower && bounds.lower <= value) {
            return true
        } else if (!bounds.upper && bounds.lower && bounds.lower <= value) {
            return true
        } else if (bounds.upper && bounds.upper > value && !bounds.lower) {
            return true
        }
        return false
    })

    if (rating === undefined) {
        return null
    } else {
        return rating
    }
}

export interface KeyPerformanceIndicatorProps {
    name: string;
    tooltip?: string;
    value: string | number | undefined;
    unit?: string;
    backgroundColor?: string;
    color?: string;
    trend?: 'up' | 'down' | 'flat';
    compact?: boolean;
    epc?: EpcGroupBounds;
    disabled?: boolean;
    selected?: boolean
    onClick?: MouseEventHandler<HTMLDivElement>;
}

const KeyPerformanceIndicator: FC<KeyPerformanceIndicatorProps> = forwardRef((props, ref) => {
    const { name, value, unit, epc } = props;

    const theme = useTheme()

    const backgroundColor = props.backgroundColor ? props.backgroundColor : props.compact ? 'null' : theme.palette.primary.main;

    const color = props.color ? props.color : props.compact ? '#000' : '#fff'

    const fontSize = props.compact ? '1.5rem' : '2rem';

    const padding = props.compact ? '5px' : '10px';

    const opacity = props.disabled ? 0.3 : 1;

    const rating = useMemo(() => epc ? getRating(epc, value as number) : null, [epc, value])

    const kpiDisplayValue = useMemo(() => {
        if (value) {
            // console.log(value, rating)
            if (rating) {
                return rating[0]
            }

            if (typeof(value) === 'number') {
                return value.toLocaleString("en-GB", { maximumSignificantDigits: 2, maximumFractionDigits: 0 })
            }

            return value
        }

        return '--'
    }, [value, rating])

    const rangeString = useMemo(() => {
        if(rating) {
            const bounds = rating[1]
            if (bounds.lower && bounds.upper) {
                return `${bounds.lower.toLocaleString('en-GB', {maximumSignificantDigits: 2})}-${bounds.upper.toLocaleString('en-GB', {maximumSignificantDigits: 2})}${unit ? unit : ''}`
            } else if (bounds.lower) {
                return `>${bounds.lower.toLocaleString('en-GB', {maximumSignificantDigits: 2})}${unit ? unit : ''}`
            } else if (bounds.upper) {
                return `<${bounds.upper.toLocaleString('en-GB', {maximumSignificantDigits: 2})}${unit ? unit : ''}`
            } else {
                return 'N/A'
            }
        }
    }, [rating, unit])

    return (
        <Stack {...props} ref={ref} component={Paper} flexDirection="row" border={props.selected ? 1 : 0} sx={{ backgroundColor, fontSize, color, padding, alignItems: 'center', flex: 1, opacity, borderColor: theme.palette.primary.main }} >
            {props.trend ? <Typography>
                {props.trend === 'up' ? <TrendingUp color='error' sx={{fontSize: '2em'}}/> : props.trend === 'down' ? <TrendingDown color='success' sx={{fontSize: '2em'}}/> : <TrendingFlat color='warning' sx={{fontSize: '2em'}}/>}
            </Typography> : null}
            <Stack flexDirection="column" flex={1} justifyContent="center" alignContent="center">
                <Typography sx={{fontSize: '0.5em', textAlign: 'center'}}>{name}</Typography>
                <Typography sx={{fontSize: '0.7em', textAlign: 'center'}}>{kpiDisplayValue}</Typography>
                {props.epc && 
                    <Stack direction="row" sx={{fontSize: '0.4em', display: "flex", padding: "2px", borderRadius: "2px", backgroundColor: "#fff", color: "#000", width: "100%", alignItems: "center"}}>
                        <Typography>A</Typography>
                        <Box sx={{flex: 1, display: "flex", justifyContent: "space-around", alignItems: "center"}}>
                            { Object.entries(epcRatingColors).map(([key, val], index, array) => {
                                const activeRating = rating && key === rating[0].toLowerCase()
                                return <Box 
                                    key={key}
                                    width="100%" 
                                    height="1em" 
                                    borderRadius="5%" 
                                    fontSize={activeRating ? "2em" : "1em"} 
                                    sx={{backgroundColor: val, display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '5px', paddingRight: '5px'}}>
                                    {rangeString && activeRating ? <Typography variant="caption" sx={{padding: 0, whiteSpace: 'nowrap'}}>{rangeString}</Typography> : null}
                                </Box>
                            })}
                        </Box>
                        <Typography>G</Typography>
                    </Stack>}
                    {!props.epc && <Typography sx={{fontSize: '0.4em', textAlign: 'center'}}>{unit}</Typography>}
            </Stack>
        </Stack>
    );
});

export default KeyPerformanceIndicator;