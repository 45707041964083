import { Link, Stack, Typography, useTheme } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom'; 
import { FC, PropsWithChildren } from "react";

interface RecaptchaProps { }

const Recaptcha: FC<PropsWithChildren<RecaptchaProps>> = (props) => {
  const theme = useTheme();
    
    return (
      <Stack 
        direction="row"
        justifyContent="space-between"
        sx={{
          paddingLeft: '1em',
          paddingRight: '1em',
          backgroundColor: theme.palette.primary.main
        }}>
        <Typography variant='caption' color="white">
          {`© CarbonLaces Ltd. ${new Date().getFullYear()} - This app is Made in London by a team who are passionate about delivering Carbon Neutral results based on Energy, Climate and Finance.`}
        </Typography>
        <Link component={RouterLink} to="/disclaimer" sx={{ 
          color: '#FFFFFF',
          textDecoration: 'underline',
          fontSize: '0.8em'}}>
            DISCLAIMER
        </Link>
      </Stack>
    )
}

export default Recaptcha;