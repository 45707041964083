import { ActionModel, PropertyModel } from "../api/cl-client";

export interface DeRisk {
    value: number;
    fraction: number;
    items: {
        behaviour: number;
        co2CostSavings: number;
        impactOnArea: number;
        propertyValue: number;
    }
}

export function getDeRisk(asset: PropertyModel, action: ActionModel): DeRisk {
    const energyCostSavingsGbp = Math.round(asset.stats.energyCostGbp - action.stats.energyCostGbp)

    const co2CostSavingsEur = asset.stats.co2CostEur - action.stats.co2CostEur

    const epcEerChange = Math.max(action.stats.virtualEpcEer - asset.stats.virtualEpcEer, 0)
    const valuationChange = asset.config.propertyValueGbp * .15 * epcEerChange / 100
    const impactOnArea = asset.config.energyCostBaselineRegional - action.stats.energyCostGbp

    const deRisk = (energyCostSavingsGbp + co2CostSavingsEur) * 10 + valuationChange + impactOnArea

    return {
        value: deRisk,
        fraction: deRisk / asset.config.propertyValueGbp,
        items: {
            behaviour: energyCostSavingsGbp,
            co2CostSavings: co2CostSavingsEur,
            impactOnArea: impactOnArea,
            propertyValue: valuationChange
        }
    }
}

export function getMaxDeRisk(asset: PropertyModel): DeRisk | undefined {
    if (!asset.packages || asset.packages.length === 0) {
        return undefined
    }

    return asset.packages?.map(action => getDeRisk(asset, action))
        .reduce((previous, current) => (
            !previous || current.value > previous.value ? current : previous
        ))
}