/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FollowupRequestModel } from '../models/FollowupRequestModel';
import type { UserModel } from '../models/UserModel';
import type { VehicleModel } from '../models/VehicleModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * Read Me
     * @returns UserModel Successful Response
     * @throws ApiError
     */
    public static readMeApiUsersMeGet(): CancelablePromise<UserModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/me',
        });
    }

    /**
     * Post Followup Request
     * @param assetId
     * @param recaptchaToken
     * @param requestBody
     * @returns FollowupRequestModel Successful Response
     * @throws ApiError
     */
    public static postFollowupRequestApiDemoAssetIdFollowupsPost(
        assetId: string,
        recaptchaToken: string,
        requestBody: FollowupRequestModel,
    ): CancelablePromise<FollowupRequestModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/demo/{asset_id}/followups',
            path: {
                'asset_id': assetId,
            },
            headers: {
                'recaptcha-token': recaptchaToken,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Vehicle Enquiry
     * @param registrationNumber
     * @returns VehicleModel Successful Response
     * @throws ApiError
     */
    public static readVehicleEnquiryApiVehicleEnquiryRegistrationNumberGet(
        registrationNumber: string,
    ): CancelablePromise<VehicleModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/vehicle-enquiry/{registration_number}',
            path: {
                'registration_number': registrationNumber,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
