interface ITarget {[year:number]: number}

export const energyTargetC15KwhSqmYr: ITarget = {
    2018: 280,
    2019: 271,
    2020: 262,
    2021: 252,
    2022: 243,
    2023: 233,
    2024: 224,
    2025: 216,
    2026: 207,
    2027: 199,
    2028: 191,
    2029: 183,
    2030: 176,
    2031: 168,
    2032: 160,
    2033: 152,
    2034: 143,
    2035: 135,
    2036: 126,
    2037: 117,
    2038: 108,
    2039: 100,
    2040: 91,
    2041: 83,
    2042: 75,
    2043: 68,
    2044: 61,
    2045: 55,
    2046: 49,
    2047: 44,
    2048: 40,
    2049: 36,
    2050: 34
}

export const energyTargetC20KwhSqmYr: ITarget = {
    2018: 280,
    2019: 272,
    2020: 264,
    2021: 256,
    2022: 249,
    2023: 242,
    2024: 235,
    2025: 228,
    2026: 221,
    2027: 215,
    2028: 209,
    2029: 203,
    2030: 197,
    2031: 191,
    2032: 185,
    2033: 180,
    2034: 175,
    2035: 170,
    2036: 165,
    2037: 160,
    2038: 155,
    2039: 151,
    2040: 147,
    2041: 142,
    2042: 138,
    2043: 134,
    2044: 130,
    2045: 127,
    2046: 123,
    2047: 119,
    2048: 116,
    2049: 112,
    2050: 109
}

export const ghgTargetC15KgCo2eSqmYr: ITarget = {
    2018: 65.5,
    2019: 61.6,
    2020: 58.4,
    2021: 55.5,
    2022: 52.8,
    2023: 50.2,
    2024: 47.7,
    2025: 45.3,
    2026: 42.9,
    2027: 40.6,
    2028: 38.3,
    2029: 36.1,
    2030: 34.0,
    2031: 32.0,
    2032: 30.1,
    2033: 28.2,
    2034: 26.3,
    2035: 24.5,
    2036: 22.7,
    2037: 20.9,
    2038: 19.2,
    2039: 17.4,
    2040: 15.7,
    2041: 14.0,
    2042: 12.3,
    2043: 10.7,
    2044: 9.3,
    2045: 7.9,
    2046: 6.7,
    2047: 5.6,
    2048: 4.7,
    2049: 4.0,
    2050: 3.4
}

export const ghgTargetC20KgCo2eSqmYr: ITarget = {
    2018: 65.5,
    2019: 63.4,
    2020: 61.9,
    2021: 60.6,
    2022: 59.4,
    2023: 58.1,
    2024: 56.7,
    2025: 55.1,
    2026: 53.3,
    2027: 51.3,
    2028: 49.2,
    2029: 47.0,
    2030: 44.8,
    2031: 42.6,
    2032: 40.5,
    2033: 38.4,
    2034: 36.5,
    2035: 34.7,
    2036: 33.0,
    2037: 31.4,
    2038: 29.8,
    2039: 28.4,
    2040: 26.9,
    2041: 25.5,
    2042: 24.1,
    2043: 22.7,
    2044: 21.4,
    2045: 20.0,
    2046: 18.7,
    2047: 17.5,
    2048: 16.4,
    2049: 15.4,
    2050: 14.7
}