import { FC, useMemo } from 'react';

import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Grid, Typography, Card, CardContent, Table, TableBody, TableHead, TableRow, TableCell, Button } from '@mui/material';
import { ResponsiveContainer, ComposedChart, CartesianGrid, XAxis, YAxis, Label, Tooltip, Legend, Line } from 'recharts';
import { energyTargetC15KwhSqmYr, energyTargetC20KwhSqmYr, ghgTargetC15KgCo2eSqmYr, ghgTargetC20KgCo2eSqmYr } from '../../utils/crremTargets';

import { isEmpty } from 'lodash';
import { useUser } from '../../components/user-context';
import KeyPerformanceIndicatorGroup, { IKeyPerformanceIndicatorExtended } from '../../components/key-performance-indicator/group';
import { getRating } from '../../components/key-performance-indicator';
import { EpcEerGroups } from '../../utils/epc';


const Landing: FC = () => {
    const navigate = useNavigate()

    const user = useUser()

    if (user.clientPrincipal?.userId === '79cd03b9-d419-431d-ad28-911a27282b5d') {
        navigate('/assets')
    }

    const kpis = useMemo(() => {
        const kpis: IKeyPerformanceIndicatorExtended[] = []
        // console.log(user.portfolio?.data)
        if (user.portfolio?.data) {
            kpis.push({
                name: "Energy Intensity",
                unit: "kWh/m2/yr",
                value: user.portfolio?.data?.portfolioStats.reduce((acc: number, item: any) => acc + item.energyIntensity, 0) / user.portfolio?.data?.portfolioStats.length,
                position: 'top',
                plots: [
                    {
                        data: user.portfolio?.data?.portfolioMonthlyStats.map((monthStats: any) => ({
                            y: monthStats['averageEnergyIntensity'],
                            x: `${monthStats['year']}/${monthStats['month']}`
                        })),
                        xLabel: 'Time',
                        yLabel: 'Energy Intensity (kWh/m2/yr)'},
                    {
                        ...user.portfolio?.data?.energyIntensityHistogram,
                        xLabel: 'Time',
                        yLabel: 'Energy Intensity (kWh/m2/yr)'}
                ]
            })

            kpis.push({
                name: "Carbon Intensity",
                unit: "kgCO2e/m2/yr",
                value: user.portfolio?.data?.portfolioStats.reduce((acc: number, item: any) => acc + item.carbonIntensity, 0) / user.portfolio?.data?.portfolioStats.length / 1000,
                position: 'top',
                plots: [
                    {
                        data: user.portfolio?.data?.portfolioMonthlyStats.map((monthStats: any) => ({
                            y: monthStats['averageCo2GIntensity'] / 1000,
                            x: `${monthStats['year']}/${monthStats['month']}`
                        })),
                        xLabel: 'Time',
                        yLabel: 'Carbon Intensity (kgCO2e/m2/yr)'},
                    {
                        ...user.portfolio?.data?.carbonIntensityHistogram,
                        buckets: user.portfolio?.data?.carbonIntensityHistogram.buckets.map((value: number) => value/1000),
                        xLabel: 'Time',
                        yLabel: 'Carbon Intensity (kgCO2e/m2/yr)'}
                ]
            })

            kpis.push({
                name: "Cost Intensity",
                unit: "£/m2/yr",
                value: user.portfolio?.data?.portfolioStats.reduce((acc: number, item: any) => acc + item.costIntensity, 0) / user.portfolio?.data?.portfolioStats.length,
                position: 'top',
                plots: [
                    {
                        data: user.portfolio?.data?.portfolioMonthlyStats.map((monthStats: any) => ({
                            y: monthStats['averageCostIntensity'],
                            x: `${monthStats['year']}/${monthStats['month']}`
                        })),
                        xLabel: 'Time',
                        yLabel: 'Cost Intensity (£/m2/yr)'},
                    {
                        ...user.portfolio?.data?.costIntensityHistogram,
                        xLabel: 'Time',
                        yLabel: 'Cost Intensity (£/m2/yr)'}
                ]
            })
            
            kpis.push({
                name: "Total Floor Area",
                unit: "m2",
                value: user.portfolio?.data?.portfolioStats.reduce((acc: number, item: any) => acc + item.floorArea, 0),
                position: 'top'
            })

            kpis.push({
                name: "ROI",
                unit: "yr",
                value: 0,
                position: 'top',
                disabled: true
            })

            kpis.push({
                name: "Energy Cost Savings",
                unit: "£",
                value: 0,
                position: 'bottom',
                disabled: true
            })

            kpis.push({
                name: "Grants",
                unit: "£",
                value: 0,
                position: 'bottom',
                disabled: true
            })

            kpis.push({
                name: "Flex Demand Income",
                unit: "£",
                value: 0,
                position: 'bottom',
                disabled: true
            })

            kpis.push({
                name: "Est. Carbon Certificate Returns",
                unit: "£",
                value: 0,
                position: 'bottom',
                disabled: true
            })

            kpis.push({
                name: "Properties with Scoped Actions",
                value: 0,
                position: 'bottom',
                disabled: true
            })

            kpis.push({
                name: "Verifications Ongoing",
                value: 0,
                position: 'bottom',
                disabled: true
            })

            kpis.push({
                name: "Verifications Completed",
                value: 0,
                position: 'bottom',
                disabled: true
            })

            kpis.push({
                name: "Verifications Failed",
                value: 0,
                position: 'bottom',
                disabled: true
            })
        }
        // console.log(kpis)
        return kpis
    }, [user.portfolio?.data])

    const graphData = useMemo(() => {
        if (user.portfolio?.data?.crremStats && !isEmpty(user.portfolio?.data.crremStats)) {
            const keys = Object.keys(user.portfolio?.data.crremStats);
            const graphData = user.portfolio?.data.crremStats.year.map((year: number, index: number) => {
                const row: any = {}
                keys.forEach((key: string) => row[key]= user.portfolio?.data.crremStats[key][index])
                row['propertyNetEnergyKWhSqmYrAvg'] = row['propertyNetEnergyKWhYrAvg'] / row['floorAreaAvg']
                row['energyTargetC15KwhSqmYr'] = energyTargetC15KwhSqmYr[year]
                row['energyTargetC20KwhSqmYr'] = energyTargetC20KwhSqmYr[year]
                row['ghgTargetC15KgCo2eSqmYr'] = ghgTargetC15KgCo2eSqmYr[year]
                row['ghgTargetC20KgCo2eSqmYr'] = ghgTargetC20KgCo2eSqmYr[year]
                row['propertyNetEnergyWithMaxRetrofitKWhSqmYrAvg'] = row['propertyNetEnergyKWhSqmYrAvg'] * (year > 2022 ? 0.11 : 1.0)
                row['ghgActualWithMaxRetrofitKgCo2eSqmYrAvg'] = row['ghgActualKgCo2eSqmYrAvg'] * (year > 2022 ? 0.05 : 1.0)
                return row})
            // console.log(graphData)
            return graphData
        } else {
            return undefined
        }
        
    }, [user.portfolio?.data])

    const portfolioPotential = useMemo(() => {
        if (!user.portfolio?.data) {
            return user.portfolio?.data 
        }

        if (user.portfolio?.data.stats.length < 2) {
            return null;
        }

        const baseline = user.portfolio?.data.stats[0]
        const packages = user.portfolio?.data.stats.slice(1)

        const keys = Object.keys(baseline);
        const percentageChange = packages.map((packageStats: any) => {
            const row: any = {}
            row['_id'] = packageStats['_id']
            const hasSolar = packageStats['_id'].includes('solar')
            keys.forEach(key => row[key] = packageStats[key])
            keys.forEach(key => row[key+"PercentChange"] = hasSolar && key === 'consumptionKwh' ? 1 - packageStats[key] / baseline[key] + 0.3 : 1 - packageStats[key] / baseline[key])
            return row
        })
        // console.log(percentageChange)
        return percentageChange
    }, [user.portfolio?.data])

    return ( 
    <>
        <Typography variant='h4'>Dashboard</Typography>
        
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <KeyPerformanceIndicatorGroup kpis={kpis} itemsPerRow={4} itemsPerColumn={4} histogramAnnotationLabel="Portfolio Average"/>
            </Grid>
            {graphData ? 
                <>
                    <Grid item xs={12} lg={6}>
                        <Card>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                Stranded Properties
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                Number of stranded properties in the portfolio for the projected year.
                                </Typography>
                                <ResponsiveContainer width="100%" height={200}>
                                        <ComposedChart width={730} height={250} data={graphData}
                                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                            <CartesianGrid opacity={0.5} vertical={false}/>
                                            <XAxis dataKey="year" axisLine={false} tickLine={false}>
                                            </XAxis>
                                            <YAxis axisLine={false} tickLine={false}>
                                                <Label angle={270} value="Stranded Properties" position="left" style={{textAnchor: 'middle'}}/>
                                            </YAxis>
                                            <Tooltip />
                                            <Legend />
                                            <Line type="monotone" dataKey="propertyStrandedIncludedC15" name="Stranded Properties (1.5°C)" dot={false} stroke="#8884d8" />
                                            <Line type="monotone" dataKey="propertyStrandedIncludedC20" name="Stranded Properties (2.0°C)" dot={false} stroke="#82ca9d"/>
                                        </ComposedChart>
                                    </ResponsiveContainer>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid xs={12} lg={6}>
                        <Card>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                Energy consumption
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                Average and standard deviation of the energy consumption for the portfolio.
                                </Typography>
                                    <ResponsiveContainer width="100%" height={200}>
                                        <ComposedChart width={730} height={250} data={graphData}
                                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                            <CartesianGrid opacity={0.5} vertical={false}/>
                                            <XAxis dataKey="year" axisLine={false} tickLine={false}>
                                            </XAxis>
                                            <YAxis axisLine={false} tickLine={false}>
                                                <Label angle={270} value="kWh/sqm/yr" position="left" style={{textAnchor: 'middle'}}/>
                                            </YAxis>
                                            <Tooltip />
                                            <Legend />
                                            <Line type="monotone" dataKey="propertyNetEnergyKWhSqmYrAvg" name="Average Energy Consumption" dot={false} stroke="#8884d8" />
                                            <Line type="monotone" dataKey="propertyNetEnergyWithMaxRetrofitKWhSqmYrAvg" name="Max impact package" dot={false} stroke="#8884d8" strokeDasharray="5 5"/>
                                            <Line type="monotone" dataKey="energyTargetC15KwhSqmYr" name="Target 1.5°C" dot={false} stroke="#FF3333" strokeDasharray="5 5"/>
                                            <Line type="monotone" dataKey="energyTargetC20KwhSqmYr" name="Target 2.0°C" dot={false} stroke="#FF3333" strokeDasharray="2 2"/>
                                        </ComposedChart>
                                    </ResponsiveContainer>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Card>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                Emissions
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                Average emissions of the portfolio as kgCO2 equivalent per square meter and year.
                                </Typography>
                                    <ResponsiveContainer width="100%" height={200}>
                                        <ComposedChart width={730} height={250} data={graphData}
                                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                            <CartesianGrid opacity={0.5} vertical={false}/>
                                            <XAxis dataKey="year" axisLine={false} tickLine={false}>
                                            </XAxis>
                                            <YAxis axisLine={false} tickLine={false}>
                                                <Label angle={270} value="kgCO2e/sqm/yr" position="left" style={{textAnchor: 'middle'}}/>
                                            </YAxis>
                                            <Tooltip />
                                            <Legend />
                                            <Line type="monotone" dataKey="ghgActualKgCo2eSqmYrAvg" name="Average GHG Emissions" dot={false} stroke="#8884d8" />
                                            <Line type="monotone" dataKey="ghgActualWithMaxRetrofitKgCo2eSqmYrAvg" name="Max impact package" dot={false} stroke="#8884d8" strokeDasharray="5 5"/>
                                            <Line type="monotone" dataKey="ghgTargetC15KgCo2eSqmYr" name="Target 1.5°C" dot={false} stroke="#FF3333" strokeDasharray="5 5"/>
                                            <Line type="monotone" dataKey="ghgTargetC20KgCo2eSqmYr" name="Target 2.0°C" dot={false} stroke="#FF3333" strokeDasharray="2 2"/>
                                        </ComposedChart>
                                    </ResponsiveContainer>
                            </CardContent>
                        </Card>
                    </Grid>
                </> : null}
            {portfolioPotential && <Grid item xs={12} lg={6}>
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                        Portfolio improvement potential
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        In this segment we explore by how much we estimate the portfolio can be improved if certain retrofit measure were to be applied to the properties in the portfolio.
                        </Typography>
                        {user.portfolio?.data && <Table size="small" aria-label="simple table">
                            <TableHead>
                            <TableRow >
                                <TableCell >Retrofit package</TableCell>
                                <TableCell align="right">Est. cost</TableCell>
                                <TableCell align="right">Energy saving</TableCell>
                                <TableCell align="right">Energy cost saving</TableCell>
                                <TableCell align="right">GHG reduction</TableCell>
                                <TableCell align="right">CLEVR™</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {portfolioPotential.map((row: any) => {
                                const rating = getRating(EpcEerGroups ,row.virtualEpcEer)
                                return <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">{row._id instanceof Array ? row._id.map((value: string) => (<>{value}<br/></>)) : row._id}</TableCell>
                                        <TableCell align="right">{row.totalCostGbp.toLocaleString('en-GB', {maximumSignificantDigits: 2, currency: 'GBP', style: "currency"})}</TableCell>
                                        <TableCell align="right">{Math.abs(row.consumptionKwhPercentChange).toLocaleString('en-GB', {style: "percent", maximumFractionDigits: 0})}</TableCell>
                                        <TableCell align="right">{Math.abs(row.energyCostGbpPercentChange).toLocaleString('en-GB', {style: "percent", maximumFractionDigits: 0})}</TableCell>
                                        <TableCell align="right">{Math.abs(row.co2GPercentChange).toLocaleString('en-GB', {style: "percent", maximumFractionDigits: 0})}</TableCell>
                                        <TableCell align="right">{rating ? rating[0] : '-'}</TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>}
                    </CardContent>
                </Card>
            </Grid>}
            {!graphData && !portfolioPotential && <Grid item xs={12} lg={6}>
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                        Create your first asset
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        Your dashboard is currently empty as there are no assets in your portfolio.
                        </Typography>
                        <Button component={RouterLink} to='/assets/post'>add your first asset</Button>
                    </CardContent>
                </Card>
            </Grid>}
        </Grid>
    </> );
}

export default Landing;