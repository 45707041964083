import { Accordion, AccordionDetails, AccordionSummary, Typography, Box, useTheme, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FC, useMemo, useState, useEffect } from 'react';

interface SimpleActionListItemProps {
    assetId: string;
    name: string;
    description: string;
    costGbp?: number;
    energySavingsPercent: number;
    energySavingsGbp: number;
    co2AvoidedPercent: number;
    unit?: string;
}

const SimpleActionListItem: FC<SimpleActionListItemProps> = (props: SimpleActionListItemProps) => {
    const itemId = useMemo(() => props.assetId + "-" + props.name.replaceAll(' ', '_'), [props.assetId, props.name])

    const [dismissed, setDismissed] = useState(JSON.parse(localStorage.getItem(itemId) ?? "false"));

    useEffect(() => {
        localStorage.setItem(itemId, JSON.stringify(dismissed));
    }, [dismissed, itemId])

    const theme = useTheme();

    const nameWidth = props.unit ? "20%" : "40%"


    const expandIcon = <ExpandMoreIcon 
        sx={{
            backgroundColor: theme.palette.primary.main,
            borderRadius: "50%",
            color: "#fff"
        }} />

    if (dismissed) {
        return null;
    }

    return (
        <Accordion 
            id={`accordion_${itemId}`}>

            <AccordionSummary 
                id={`accordion_summary_${itemId}`}
                expandIcon={expandIcon}>
                <Typography variant='body2' fontWeight={600} width={nameWidth}>{props.name}</Typography>
                {props.unit && <Box width="20%" textAlign="center">
                    <Typography variant='body2'>Unit</Typography>
                    <Typography variant='body2' fontWeight={600}>{props.unit}</Typography>
                </Box>}
                <Box width="20%" textAlign="center">
                    <Typography variant='body2'>Costs</Typography>
                    <Typography variant='body2' fontWeight={600}>{(!props.costGbp || props.costGbp === 0) ? 'FREE' : `${props.costGbp.toLocaleString('en-GB', {maximumSignificantDigits: 2, currency: 'GBP', style: "currency"})}`}</Typography>
                </Box>
                <Box width="20%" textAlign="center">
                    <Typography variant='body2'>Energy cost savings p.a.</Typography>
                    <Typography variant='body2' fontWeight={600}>{props.energySavingsPercent.toLocaleString('en-GB', {style: "percent", maximumFractionDigits: 0})} ({props.energySavingsGbp.toLocaleString('en-GB', {maximumSignificantDigits: 2, currency: 'GBP', style: "currency"})})</Typography>
                </Box>
                <Box width="20%" textAlign="center">
                    <Typography variant='body2'>CO₂ avoided</Typography>
                    <Typography variant='body2' fontWeight={600}>{props.co2AvoidedPercent.toLocaleString('en-GB', {style: "percent", maximumFractionDigits: 0})}</Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails
                id={`accordion_details_${itemId}`}>
                <Typography>{props.description}</Typography>
                <Button variant="contained" color="primary" sx={{width: '100%'}} onClick={() => setDismissed(true)}>Dismiss action</Button>
            </AccordionDetails>
        </Accordion>)
}

export default SimpleActionListItem;