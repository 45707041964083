
import { FC } from 'react';
import { useMatch, Link, PathMatch } from 'react-router-dom';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

export interface NavItemProps {
    to: string;
    icon?: React.ReactNode;
    title: string;
    children?: NavItemProps[];
    level?: number;
    isVisible?: (match: PathMatch<string>) => boolean;
    isActive?: (match: PathMatch<string>) => boolean;
}

export const NavItem: FC<NavItemProps> = (props) => {
  const { to, icon, title, isVisible, isActive, children, ...others } = props;
  const pathActive = useMatch(to + "/*") !== null;

  const level = props.level || 0;

  const match = useMatch(to + "/*")
  const isActiveSate = isActive && match ? isActive(match) : pathActive;

  if (isVisible || isActive) {
    // console.log(match)
  }

  if (isVisible && (!match || !isVisible(match))) {
    return null;
  }

  return (
    <>
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        mb: 0.5,
        py: 0,
        px: 2
      }}
      {...others}
    >
        <ListItemButton
            selected={isActiveSate}
            component={Link} 
            to={match ? match.pathnameBase : to} 
        >
            {icon ? <ListItemIcon>icon</ListItemIcon> : undefined }
            <ListItemText primary={title} sx={{paddingLeft: `${level}em`}} />
        </ListItemButton>
    </ListItem>
      {children && pathActive ? children?.map((item, index) => <NavItem key={item.title} level={level+1} {...item} />) : undefined}
    </>
  );
};
