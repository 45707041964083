import { FC, useMemo, useState, lazy, Suspense } from 'react';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import { useParams } from 'react-router-dom';

const SmartMeterSignUp = lazy(() => import("../../components/device-signup-smart-meter"));
const DaikinSignUp = lazy(() => import("../../components/device-signup-daikin"));

interface DeviceForm {
    name: string;
    type: string;
    
    form: JSX.Element;
}

const EditAssetDevices: FC = () => {
    const params = useParams();

    const devices: DeviceForm[] = useMemo(() => ([
        {name: "Smart Meter", type: "Smart Meter", form: params.assetId ? <SmartMeterSignUp assetId={params.assetId}/> : <Typography variant='h4'>No asset selected</Typography> },
        {name: "Daikin", type: "Heat Pump", form: <DaikinSignUp /> },
    ]), [params.assetId]);

    const [device, setDevice] = useState<string>(devices[0].name + '|' + devices[0].type)

    const form = useMemo(() => {
        if (device) {
            const deviceParts = device.split("|");
            const selectedDevice = devices.find(d => d.name === deviceParts[0] && d.type === deviceParts[1])
            if (selectedDevice) {
                return selectedDevice.form
            }
        }
    }, [device, devices])

    return (
    <Grid container spacing={1}>
        <Grid item xs={12}>
            <Paper>
                <Typography variant='h5'>Select device type</Typography>
                <Select defaultValue={devices[0].name + '|' + devices[0].type} onChange={(value) => setDevice(value.target.value)}>
                    {devices.map((item, index) => <MenuItem value={item.name + '|' + item.type} key={index}>{item.name}</MenuItem>)}
                </Select>
            </Paper>
        </Grid>

        <Grid item xs={12}>
            <Suspense fallback={<Typography variant='h4'>Loading...</Typography>}>
                {form}
            </Suspense>
        </Grid>
    </Grid> );
}

export default EditAssetDevices;