import { Box, Paper, Container } from '@mui/material';
import { FC } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { AssetStatusEnum, DemoService } from '../../api/cl-client';
import DemoProperty from '../../components/asset-view-demo-property';
import Footer from '../../components/footer';


const Outcomes: FC = () => {
    const params = useParams();

    const queryClient = useQueryClient();

    const queryId = '/demo/' + params.assetId;
    const assetQuery = useQuery([queryId], () => DemoService.readAssetApiDemoAssetIdGet(params.assetId as string), {
        enabled: !!params.assetId,
        onSuccess: (data) => {
            if (data.status !== AssetStatusEnum.DONE) {
                setTimeout(() => {
                    queryClient.invalidateQueries(queryId)
                }, 10000)
            }
        }
    });

    return (
        <>
            <Box sx={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                backgroundImage: "url('/leaf-background.jpg')",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundAttachment: "fixed",
                justifyContent: "stretch",
                alignItems: 'center',
                }}>
                <Container 
                    component={Paper}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        padding: '20px',
                    }}>
                    {assetQuery.data && <DemoProperty asset={assetQuery.data}/>}
                </Container>
            </Box> 
            <Footer></Footer>
        </>
    );
}

export default Outcomes;