import { FC } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { AssetStatusEnum, AssetTypeEnum, AssetsService, VehicleModel } from '../../api/cl-client';

import AssetViewProperty from '../../components/asset-view-property';
import AssetViewVehicle from '../../components/asset-view-vehicle';
import { useQuery, useQueryClient } from 'react-query';

interface EditAssetProps {
}

const EditAsset: FC<EditAssetProps> = () => {
    const navigate = useNavigate()

    const params = useParams();

    const queryClient = useQueryClient()

    const queryId = '/assets/' + params.assetId;
    const assetQuery = useQuery([queryId], () => AssetsService.readAssetApiAssetsAssetIdGet(params.assetId as string), {
        enabled: !!params.assetId,
        onSuccess: (data) => {
            if (data.status !== AssetStatusEnum.DONE) {
                setTimeout(() => {
                    queryClient.invalidateQueries(queryId)
                }, 10000)
            }
        },
        onError: () => {
            navigate("/assets")
        }
    });


    return (
        <>
            {assetQuery.data?.assetType === AssetTypeEnum.PROPERTY && <AssetViewProperty asset={assetQuery.data}/>}
            {assetQuery.data?.assetType === VehicleModel.assetType.VEHICLE && <AssetViewVehicle asset={assetQuery.data}/>}
        </>
    )
}

export default EditAsset;