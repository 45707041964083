import { useContext, createContext, useEffect, useState, PropsWithChildren, useCallback } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { AssetsService, LandModel, PortfolioService, PropertyModel, VehicleModel } from "../api/cl-client";

import { clear, fetchMe } from '../app/features/user-slice'

import { useAppDispatch } from '../app/hooks'

const SAVED_QUERY_PARAMS_KEY = "authRedirectsavedQueryParams"

export interface IClientPrincipal {
  identityProvider: string;
  userId: string;
  userDetails: string;
  userRoles: string[];
}

export interface IUserContext {
  clientPrincipal?: IClientPrincipal | null

  login: (redirectUrl?: string) => void
  logout: (redirectUrl?: string) => void

  isAuthenticated: boolean

  portfolio?: UseQueryResult<any>
  assets?: UseQueryResult<(PropertyModel | VehicleModel | LandModel)[]>
  getAsset?: (assetId: string) => PropertyModel | VehicleModel | LandModel | undefined
}

const userContext = createContext<IUserContext>({ 
  login,
  logout,
  isAuthenticated: false
});

export function ProvideUser({ children }: PropsWithChildren<{}>) {
  const auth = useProvideUser();
  return (
    <userContext.Provider value={auth}>
      {children}
    </userContext.Provider>
  );
}

export function useUser() {
  return useContext<IUserContext>(userContext);
}

function login(redirectUrl?: string) {
  const url = new URL(`/.auth/login/aad-b2c`, window.location.origin)
  if (redirectUrl) {
    url.searchParams.append('post_login_redirect_uri', redirectUrl)
  }
  // console.log('redirecting to: ', url)
  window.location.assign(url.href)
}

function logout(redirectUrl?: string) {
  const url = new URL(`/.auth/logout`, window.location.origin)
  if (redirectUrl) {
    url.searchParams.append('post_logout_redirect_uri', redirectUrl)
  }
  // console.log('redirecting to: ', url)
  window.location.assign(url.href)
}

export function useProvideUser(): IUserContext {
  const [clientPrincipal, setClientPrincipal] = useState<IClientPrincipal | undefined | null>(undefined);

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const portfolio = useQuery(["portfolio"], () => PortfolioService.getPortfolioApiPortfolioGet(), {enabled: isAuthenticated});

  const assets = useQuery(["assets"], () => AssetsService.getAssetsApiAssetsGet(), {enabled: isAuthenticated});

  const dispatch = useAppDispatch()

  const getAsset = useCallback((assetId: string) => {
    if (assets && assets.data) {
      return assets.data.find(asset => asset._id === assetId)
    } else {
      return undefined
    }
  }, [assets])

  useEffect(() => {
    const queryString = localStorage.getItem(SAVED_QUERY_PARAMS_KEY);
    if (queryString) {
        window.location.assign(`${window.location.href}${queryString}`);
        localStorage.removeItem(SAVED_QUERY_PARAMS_KEY);
    }
  }, [])

  useEffect(() => {
    fetch('/.auth/me')
    .then(response => response.json())
    .then(({ clientPrincipal }: { clientPrincipal: IClientPrincipal }) => {
      setClientPrincipal(clientPrincipal);
    })
  }, [])

  useEffect(() => {
    const newIsAuthenticated = !!clientPrincipal;

    if (newIsAuthenticated !== isAuthenticated) {
      if (newIsAuthenticated) {
        dispatch(fetchMe())
        // dispatch(fetchUserById(clientPrincipal.userId))
      }
      else {
        dispatch(clear())
      }
    }

    setIsAuthenticated(newIsAuthenticated)
  }, [clientPrincipal, dispatch, isAuthenticated]);

  return {
    clientPrincipal,
    login,
    logout,
    isAuthenticated: isAuthenticated,
    portfolio,
    assets,
    getAsset
  };
}

//function RequireAuth() {
//  let auth = useAuth();
//  let location = useLocation();
//
//  if (!auth.isAuthenticated) {
//    // Redirect them to the /login page, but save the current location they were
//    // trying to go to when they were redirected. This allows us to send them
//    // along to that page after they login, which is a nicer user experience
//    // than dropping them off on the home page.
//    return <Navigate to="/login" state={{ from: location }} />;
//  }
//
//  return <Outlet />;
//}
