import { forwardRef, useMemo, FC, useState, useCallback, useEffect } from 'react';
import KeyPerformanceIndicator, { KeyPerformanceIndicatorProps as IKeyPerformanceIndicatorProps } from './index'
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack'
import { Paper, useTheme } from '@mui/material';
import Chart from "react-apexcharts";

interface IPlotData {
    data: {
        x: number | string;
        y: number;
    }[];
    yLabel?: string;
    xLabel?: string;
}

interface IHistogramPlotData {
    count: number[];
    buckets: number[];
    yLabel?: string;
    xLabel?: string;
}

export interface IKeyPerformanceIndicatorExtended extends IKeyPerformanceIndicatorProps {
    position?: 'left' | 'top' | 'right' | 'bottom';
    plots?: (IPlotData | IHistogramPlotData)[];
}

interface IKeyPerformanceIndicatorGroupProps {
    kpis: IKeyPerformanceIndicatorExtended[];
    itemsPerRow: number;
    itemsPerColumn: number;
    histogramAnnotationLabel: string;
}


const KeyPerformanceIndicatorGroup: FC<IKeyPerformanceIndicatorGroupProps> = forwardRef((props, ref) => {
    const theme = useTheme()

    const [selectedKpi, setSelectedKpi] = useState<IKeyPerformanceIndicatorExtended | undefined>()

    useEffect(() => {
        if (props.kpis && props.kpis.length) {
            const defaultKpi = props.kpis.find(kpi => kpi.plots && kpi.plots.length)
            setSelectedKpi(defaultKpi)
        }
    }, [props.kpis])

    const onKpiSelected = useCallback((kpi: IKeyPerformanceIndicatorExtended) => {
        setSelectedKpi(kpi)
    }, [setSelectedKpi])

    const getKpiGrid = useCallback((id: string, kpis: IKeyPerformanceIndicatorExtended[], direction: 'row' | 'column') => {
        if (!kpis.length) {
            return null;
        }

        return <Grid key={id} container spacing={1} direction={direction} justifyContent="center">
            {kpis.map((kpi, index) => 
                <Grid key={`${id}-${index}`} xs={direction === 'row' ? 12 / props.itemsPerRow : 12 / props.itemsPerColumn}>
                    <KeyPerformanceIndicator 
                        {...kpi} 
                        onClick={() => !kpi.disabled && kpi.plots?.length ? onKpiSelected(kpi) : null} 
                        selected={kpi === selectedKpi} compact />
                </Grid>)}
        </Grid>
    }, [props.itemsPerRow, props.itemsPerColumn, onKpiSelected, selectedKpi])

    const getChartComponent = useCallback((kpi: IKeyPerformanceIndicatorExtended, plot: IPlotData | IHistogramPlotData) => {
        if ('buckets' in plot) {
            return <Chart
                options={{
                    chart: {
                        type: 'bar'
                    },
                    dataLabels: {enabled: false},
                    xaxis: {
                        type: "numeric",
                        min: 0,
                        max: plot.buckets[plot.buckets.length - 1],
                        tickAmount: plot.count.length,
                        tickPlacement: 'on',
                        labels: {formatter: (x: string) => Number(x).toLocaleString('en-GB', {maximumSignificantDigits: 2})},
                        decimalsInFloat: 0,
                        title: {
                            text: `${kpi.name} (${kpi.unit})`, offsetY: 70
                        }
                    },
                    annotations: {
                        xaxis: [{
                            x: (kpi.value as number),
                            borderColor: '#00E396',
                            label: {
                                borderColor: '#00E396',
                                style: {
                                    color: '#fff',
                                    background: '#00E396'
                                },
                                text: props.histogramAnnotationLabel
                            }
                        }]
                    },
                    yaxis: {
                        decimalsInFloat: 1,
                        title: {
                            text: `Number of Assets`
                        }
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: '95%'
                        }
                    }
                }}
                series={[{
                    name: 'Counts',
                    color: theme.palette.primary.main,
                    data: plot.count.map(
                        (value, index) => ({
                            x: plot.buckets[index] + (plot.buckets[index+1] - plot.buckets[index]) / 2,
                            y: value
                        })
                    )
                }]} 
                type="bar"
                style={{width: '100%'}}
                height="250px"
            />
        } else {
            return <Chart
                options={{
                    xaxis: {
                        categories: plot.data?.map(data => data.x)
                    },
                    yaxis: {
                        decimalsInFloat: 1,
                        title: {
                            text: `${kpi.name} (${kpi.unit})`
                        }
                    }
                }}
                series={[{
                    name: kpi.name,
                    color: theme.palette.primary.main,
                    data: plot.data?.map(data => data.y)
                }]}
                type="line"
                style={{width: '100%'}}
                height="250px"
            />
        }
    }, [theme.palette.primary.main, props.histogramAnnotationLabel])
    
    const topKpiComponents = useMemo(() => getKpiGrid('topKpis', props.kpis.filter(item => item.position === 'top'), 'row'), [props, getKpiGrid])
    const rightKpiComponents = useMemo(() => getKpiGrid('rightKpis', props.kpis.filter(item => item.position === 'right'), 'column'), [props, getKpiGrid])
    const bottomKpiComponents = useMemo(() => getKpiGrid('bottomKpis', props.kpis.filter(item => item.position === 'bottom'), 'row'), [props, getKpiGrid])
    const leftKpiComponents = useMemo(() => getKpiGrid('leftKpis', props.kpis.filter(item => item.position === 'left'), 'column'), [props, getKpiGrid])

    const graphComponents: JSX.Element[] | undefined = useMemo(() => selectedKpi?.plots?.map(plot => getChartComponent(selectedKpi, plot)), [selectedKpi, getChartComponent])

    return (
        <Stack direction="row">
            {leftKpiComponents}
            <Stack direction="column" width="100%" spacing={1}>
                {topKpiComponents}
                <Stack component={Paper} sx={{position: 'relative', flexDirection: { xs: "column", md: "row"}}} >
                    {graphComponents}
                </Stack> 
                {bottomKpiComponents}
            </Stack>
            {rightKpiComponents}
        </Stack>
    );
});

export default KeyPerformanceIndicatorGroup;