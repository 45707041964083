import { FC, useState } from 'react';
import { AppBar, Box, IconButton, Toolbar, useMediaQuery, Theme, MenuItem, Menu, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HelpIcon from '@mui/icons-material/Help';
import { SignInButton } from './signin-button';
import { SignOutButton } from './signout-button';
import { useUser } from './user-context';

import { Logo } from './logo';
import { Link } from 'react-router-dom';

interface DashboardNavbarProps {
    onSidebarOpen: () => void;
}

export const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onSidebarOpen, ...other } = props;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'), {
    defaultMatches: true,
    noSsr: false
  });

  const auth = useUser();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar
        sx={{ 
            left: {
                xs: "0px",
                lg: auth.isAuthenticated ? "280px" : "0px"
            },
            width: {
                xs: "100%",
                lg: auth.isAuthenticated ? "calc(100% - 280px)" : "100%"
            }
        }}
        {...other}>
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2
          }}
        >
            { lgUp && auth.isAuthenticated ? undefined : <Link to="/"><Logo width="240" /></Link>}
          { auth.isAuthenticated && <IconButton
            onClick={onSidebarOpen}
            sx={{
              display: {
                xs: 'inline-flex',
                lg: 'none'
              }
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>}
          <Box sx={{ flexGrow: 1 }} />
          <Button
            color='inherit'
            href="https://carbonlaces.com/datasets">
            Data
          </Button>
          { !auth.isAuthenticated ? <SignInButton /> : <SignOutButton /> }
          <div>
            <IconButton
              size="large"
              aria-label="Help"
              aria-controls="menu-help"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit">
              <HelpIcon />
            </IconButton>
            <Menu
              id="menu-help"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem 
                href="https://carbonlaces.canny.io/feature-requests"
                target="_blank"
                component="a" >
                Feature requests
              </MenuItem>
              <MenuItem 
                href="mailto:support@carbonlaces.com"
                target="_top"
                component="a" >
                Contact support
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};
