import { FC } from 'react';

import Button from '@mui/material/Button';

interface SignInButtonProps {
    href?: string;
    title?: string;
    icon?: React.ReactNode;
}

export const SignInButton: FC<SignInButtonProps> = (props) => {
    return (
        <Button
            color='inherit'
            href={props.href ? props.href : `/.auth/login/aad-b2c`}
        >
            { props.title ? props.title : 'Sign In' }
        </Button>
    );
}