import { styled } from '@mui/material/styles';

interface LogoProps {
    variant?: 'light' | 'primary';
    width?: string;
}

export const Logo = styled((props: LogoProps) => {
  const { variant } = props;

  const color = variant === 'light' ? '#007045' : '#fff';
  const width = props.width ? props.width : '100%';

  return (
    <svg version="1.1" id="Layer_1" x="0px" y="0px"
	 viewBox="0 0 1748 186.35" fill={color} width={width}>
    <polygon points="1013.87,8.15 1037,8.15 1037,158.38 1124.51,158.38 1124.51,180.55 1013.87,180.55 "/>
    <path d="M1270.44,180.55h26.47L1221.55,1h-1.43l-75.11,179.55h22.89l18.44-45.3h66.3L1270.44,180.55z M1194.5,115.22
        l25.59-62.86l24.69,62.86H1194.5z"/>
    <path d="M1446.65,167.67c-2.86,1.91-6.76,4.05-11.68,6.44c-4.93,2.39-10.69,4.38-17.29,5.96
        c-6.6,1.59-13.87,2.38-21.82,2.38c-13.2-0.16-25.16-2.54-35.89-7.15c-10.73-4.61-19.87-10.89-27.42-18.84
        c-7.55-7.95-13.32-17.13-17.29-27.54c-3.98-10.41-5.96-21.5-5.96-33.26c0-12.88,2.06-24.76,6.2-35.65
        c4.13-10.89,10.05-20.35,17.76-28.38c7.71-8.03,16.77-14.27,27.18-18.72c10.41-4.45,21.82-6.68,34.22-6.68
        c10.33,0,19.71,1.39,28.14,4.17c8.42,2.78,15.66,6,21.7,9.66l-9.3,21.94c-4.77-3.34-10.57-6.44-17.41-9.3
        c-6.84-2.86-14.31-4.29-22.41-4.29c-8.43,0-16.38,1.67-23.84,5.01c-7.47,3.34-14.07,8.03-19.79,14.07
        c-5.72,6.04-10.22,13.04-13.47,20.98c-3.26,7.95-4.89,16.61-4.89,25.99c0,9.22,1.47,17.85,4.41,25.87
        c2.94,8.03,7.15,15.02,12.64,20.98c5.48,5.96,12.12,10.61,19.91,13.95c7.79,3.34,16.53,5.01,26.23,5.01
        c8.74,0,16.53-1.35,23.37-4.05c6.83-2.7,12.48-5.72,16.93-9.06L1446.65,167.67z"/>
    <polygon points="1485.51,8.15 1597.58,8.15 1597.58,30.33 1508.64,30.33 1508.64,82.79 1588.05,82.79 1588.05,105.2 
        1508.64,105.2 1508.64,158.38 1600.92,158.38 1600.92,180.55 1485.51,180.55 "/>
    <path d="M1730.16,38.91c-5.88-3.02-12.32-5.64-19.31-7.87c-7-2.22-13.83-3.34-20.51-3.34c-9.22,0-16.57,2.15-22.06,6.44
        c-5.48,4.29-8.23,10.01-8.23,17.17c0,5.41,1.79,9.98,5.37,13.71c3.58,3.74,8.19,6.96,13.83,9.66c5.64,2.7,11.64,5.24,18,7.63
        c5.25,1.91,10.49,4.17,15.74,6.8c5.25,2.62,9.97,5.84,14.19,9.66c4.21,3.81,7.55,8.51,10.01,14.07c2.46,5.57,3.7,12.4,3.7,20.51
        c0,9.22-2.27,17.57-6.79,25.04c-4.53,7.47-11.01,13.35-19.43,17.64c-8.43,4.29-18.52,6.44-30.28,6.44c-7.48,0-14.67-0.83-21.58-2.5
        c-6.91-1.67-13.32-3.94-19.19-6.8c-5.88-2.86-11.13-5.8-15.74-8.82l10.25-18.12c3.81,2.7,8.19,5.29,13.11,7.75
        c4.93,2.47,10.09,4.41,15.5,5.84c5.4,1.43,10.65,2.15,15.74,2.15c5.56,0,10.97-0.95,16.21-2.86c5.25-1.91,9.62-4.93,13.11-9.06
        c3.5-4.13,5.25-9.62,5.25-16.45c0-5.72-1.55-10.57-4.65-14.55c-3.1-3.97-7.15-7.35-12.16-10.13c-5.01-2.78-10.45-5.28-16.33-7.51
        c-5.57-1.91-11.09-4.13-16.57-6.68c-5.48-2.54-10.57-5.6-15.26-9.18c-4.69-3.58-8.51-7.99-11.45-13.23
        c-2.94-5.25-4.41-11.6-4.41-19.08c0-9.06,2.18-16.97,6.56-23.73c4.37-6.75,10.37-12.08,18-15.98c7.63-3.89,16.37-6,26.23-6.32
        c11.13,0,20.94,1.35,29.45,4.05c8.5,2.7,16.01,5.96,22.53,9.78L1730.16,38.91z"/>
    <path d="M146.03,167.99c-2.86,1.91-6.76,4.06-11.69,6.44c-4.93,2.39-10.69,4.38-17.29,5.96
        c-6.6,1.59-13.87,2.39-21.82,2.39c-13.2-0.16-25.16-2.54-35.89-7.15c-10.73-4.61-19.87-10.89-27.42-18.84
        c-7.55-7.95-13.32-17.13-17.29-27.54c-3.98-10.41-5.96-21.5-5.96-33.26c0-12.88,2.07-24.76,6.2-35.65
        c4.13-10.89,10.05-20.34,17.76-28.38c7.71-8.03,16.77-14.26,27.18-18.72c10.41-4.45,21.82-6.68,34.22-6.68
        c10.33,0,19.71,1.39,28.14,4.17c8.42,2.78,15.66,6,21.7,9.66l-9.3,21.94c-4.77-3.34-10.57-6.44-17.41-9.3
        c-6.84-2.86-14.31-4.29-22.41-4.29c-8.43,0-16.38,1.67-23.85,5.01c-7.47,3.34-14.07,8.03-19.79,14.07
        c-5.72,6.04-10.21,13.04-13.47,20.98c-3.26,7.95-4.89,16.61-4.89,25.99c0,9.22,1.47,17.85,4.41,25.87
        c2.94,8.03,7.15,15.02,12.64,20.98c5.49,5.96,12.12,10.61,19.91,13.95c7.79,3.34,16.53,5.01,26.23,5.01
        c8.74,0,16.53-1.35,23.37-4.05c6.83-2.7,12.48-5.72,16.93-9.06L146.03,167.99z"/>
    <path d="M293.14,180.86h26.47L244.26,1.31h-1.43l-75.11,179.55h22.89l18.44-45.3h66.3L293.14,180.86z M217.21,115.53
        l25.59-62.86l24.68,62.86H217.21z"/>
    <path d="M473.64,180.86l-43.14-69.39c6.34-3.11,11.54-7.04,15.6-11.8c4.53-5.32,7.79-11.24,9.78-17.76
        c1.99-6.51,2.98-13.19,2.98-20.03c0-8.58-1.55-16.21-4.65-22.89c-3.1-6.68-7.39-12.28-12.88-16.81
        c-5.48-4.53-11.92-7.95-19.31-10.26c-7.39-2.3-15.46-3.46-24.2-3.46h-45.78v172.4h23.13v-63.43h25.52c1.89,0,3.7-0.07,5.48-0.16
        l40.78,63.59H473.64z M400.2,95.02h-25.04V30.64h26.71c6.04,0,11.16,1.03,15.38,3.1c4.21,2.07,7.63,4.73,10.25,7.99
        c2.62,3.26,4.53,6.72,5.72,10.37c1.19,3.66,1.79,7.23,1.79,10.73c0,3.5-0.56,7.07-1.67,10.73c-1.11,3.66-2.94,7.12-5.48,10.37
        c-2.55,3.26-6.08,5.92-10.61,7.99C412.72,93.99,407.04,95.02,400.2,95.02"/>
    <path d="M609.56,108.14c-5.41-6.83-12.72-12.16-21.93-15.98c-0.6-0.25-1.21-0.47-1.82-0.7
        c0.01-0.01,0.02-0.01,0.03-0.02c7.39-3.82,13.15-9.06,17.29-15.74c4.13-6.68,6.2-14.62,6.2-23.85c0-14.78-4.97-25.71-14.9-32.79
        c-9.94-7.07-23.57-10.61-40.9-10.61h-44.35v172.4h51.26c7.47,0,14.58-0.91,21.34-2.74c6.75-1.83,12.87-4.69,18.36-8.58
        c5.48-3.9,9.78-8.9,12.87-15.02c3.1-6.12,4.65-13.47,4.65-22.06C617.67,123.08,614.96,114.97,609.56,108.14 M532.3,30.64h22.41
        c9.38,0,16.85,2.07,22.42,6.2c5.56,4.14,8.34,10.81,8.34,20.03c0,7.47-2.15,13.83-6.44,19.08c-4.29,5.25-11.21,7.87-20.74,7.87
        H532.3V30.64z M589.41,147.6c-3.1,4.05-7.27,7.04-12.52,8.94c-5.25,1.91-11.13,2.86-17.65,2.86H532.3v-53.17h25.51
        c5.88,0,11.6,0.83,17.17,2.5c5.56,1.67,10.14,4.41,13.71,8.23c3.58,3.81,5.36,9.06,5.36,15.74
        C594.06,138.58,592.51,143.55,589.41,147.6"/>
    <polygon points="963.97,8.47 964.43,133.47 838.08,3.22 836.88,3.22 836.88,180.86 860.01,180.86 859.55,57.94 
        986.15,186.35 987.1,186.35 987.1,8.47 "/>
    <g>
        <path d="M787.71,30.66c-16.14-16.14-38.48-26.14-63.11-26.14c-10.86,0-21.28,1.95-30.91,5.5
            c-2.03-1.9-4.74-3.07-7.73-3.07c-6.25,0-11.32,5.07-11.32,11.32c0,0.5,0.04,0.98,0.1,1.46c-4.77,3.22-9.21,6.88-13.25,10.93
            c-16.14,16.14-26.14,38.48-26.14,63.11c0,24.63,10,46.97,26.14,63.11c16.14,16.14,38.48,26.14,63.11,26.14
            c24.63,0,46.97-10,63.11-26.14c16.14-16.14,26.14-38.48,26.14-63.11C813.86,69.14,803.86,46.8,787.71,30.66 M781.31,150.47
            c-14.53,14.52-34.55,23.49-56.71,23.49c-22.16,0-42.18-8.97-56.71-23.49c-14.52-14.53-23.49-34.55-23.49-56.71
            c0-22.16,8.97-42.18,23.49-56.71c3.53-3.53,7.4-6.73,11.53-9.55c1.85,1.31,4.09,2.08,6.53,2.08c6.22,0,11.27-5.02,11.31-11.23
            c8.53-3.09,17.73-4.78,27.33-4.78c22.16,0,42.18,8.97,56.71,23.49c14.52,14.53,23.49,34.55,23.49,56.71
            C804.8,115.93,795.83,135.95,781.31,150.47"/>
        <path d="M785.99,67.13c-3.54-8.14-8.42-15.35-14.64-21.63c-6.22-6.28-13.43-11.19-21.63-14.73
            c-8.2-3.54-16.97-5.31-26.3-5.31c-9.32,0-18.06,1.77-26.2,5.31c-8.14,3.54-15.32,8.46-21.54,14.73
            c-6.22,6.28-11.07,13.49-14.55,21.63c-3.48,8.14-5.22,16.88-5.22,26.2c0,9.7,1.74,18.65,5.22,26.86
            c3.48,8.21,8.33,15.35,14.55,21.44c6.21,6.1,13.39,10.82,21.54,14.17c8.14,3.36,16.88,5.04,26.2,5.04c8.44,0,16.42-1.44,23.94-4.29
            c1.84,1.3,4.08,2.06,6.5,2.06c6.25,0,11.32-5.07,11.32-11.32c0-0.25-0.02-0.5-0.04-0.75c2.16-1.69,4.24-3.5,6.2-5.47
            c6.22-6.21,11.1-13.39,14.64-21.54c3.54-8.14,5.32-16.88,5.32-26.2C791.31,84.01,789.53,75.28,785.99,67.13 M777.41,116.27
            c-3.05,7.09-7.24,13.31-12.59,18.65c-1.25,1.25-2.54,2.42-3.88,3.54c-1.94-1.55-4.39-2.49-7.07-2.49
            c-6.25,0-11.32,5.07-11.32,11.32c0,0.53,0.05,1.05,0.12,1.56c-6.09,2.14-12.56,3.22-19.43,3.22c-7.96,0-15.45-1.52-22.47-4.57
            c-7.02-3.05-13.21-7.24-18.55-12.59c-5.35-5.34-9.51-11.56-12.49-18.65c-2.98-7.09-4.48-14.67-4.48-22.75
            c0-7.96,1.46-15.51,4.38-22.66c2.92-7.15,7.02-13.46,12.31-18.93c5.28-5.47,11.47-9.79,18.56-12.96
            c7.09-3.17,14.73-4.75,22.94-4.75c8.08,0,15.67,1.56,22.75,4.66c7.09,3.11,13.3,7.37,18.65,12.77c5.35,5.41,9.54,11.69,12.59,18.84
            c3.04,7.15,4.57,14.76,4.57,22.84C781.98,101.54,780.46,109.19,777.41,116.27"/>
    </g>
    </svg>
  );
})``;
