/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum PackageItemsEnum {
    SOLAR = 'solar',
    BATTERY = 'battery',
    HEAT_PUMP = 'heat_pump',
    INSULATION = 'insulation',
}
