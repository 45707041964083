/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssetTypeEnum } from '../models/AssetTypeEnum';
import type { DeviceModel } from '../models/DeviceModel';
import type { LandModel } from '../models/LandModel';
import type { MeterDataModel } from '../models/MeterDataModel';
import type { MonthlyMeterDataModel } from '../models/MonthlyMeterDataModel';
import type { PropertyModel } from '../models/PropertyModel';
import type { SetRetrofitStartDateModel } from '../models/SetRetrofitStartDateModel';
import type { UpdateLandModel } from '../models/UpdateLandModel';
import type { UpdatePropertyModel } from '../models/UpdatePropertyModel';
import type { UpdateVehicleModel } from '../models/UpdateVehicleModel';
import type { VehicleModel } from '../models/VehicleModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AssetsService {

    /**
     * Get Assets
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getAssetsApiAssetsGet(): CancelablePromise<Array<(PropertyModel | VehicleModel | LandModel)>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/assets',
        });
    }

    /**
     * Post Asset
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static postAssetApiAssetsPost(
        requestBody: (PropertyModel | VehicleModel | LandModel),
    ): CancelablePromise<(PropertyModel | VehicleModel | LandModel)> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/assets',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Assets
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteAssetsApiAssetsDelete(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/assets',
        });
    }

    /**
     * Get Assets Csv
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getAssetsCsvApiAssetsCsvGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/assets/csv',
        });
    }

    /**
     * Get Asset Types
     * @returns AssetTypeEnum Successful Response
     * @throws ApiError
     */
    public static getAssetTypesApiAssetsTypesGet(): CancelablePromise<Array<AssetTypeEnum>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/assets/types',
        });
    }

    /**
     * Read Asset
     * @param assetId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static readAssetApiAssetsAssetIdGet(
        assetId: string,
    ): CancelablePromise<(PropertyModel | VehicleModel | LandModel)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/assets/{asset_id}',
            path: {
                'asset_id': assetId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Asset
     * @param assetId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static readAssetApiAssetsAssetIdPut(
        assetId: string,
        requestBody: (UpdatePropertyModel | UpdateVehicleModel | UpdateLandModel),
    ): CancelablePromise<(PropertyModel | VehicleModel | LandModel)> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/assets/{asset_id}',
            path: {
                'asset_id': assetId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Asset
     * @param assetId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteAssetApiAssetsAssetIdDelete(
        assetId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/assets/{asset_id}',
            path: {
                'asset_id': assetId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Asset Meter Data
     * @param assetId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static readAssetMeterDataApiAssetsAssetIdMeterDataGet(
        assetId: string,
    ): CancelablePromise<Array<(MeterDataModel | MonthlyMeterDataModel)>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/assets/{asset_id}/meterData',
            path: {
                'asset_id': assetId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Asset Meter Data
     * @param assetId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateAssetMeterDataApiAssetsAssetIdMeterDataPut(
        assetId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/assets/{asset_id}/meterData',
            path: {
                'asset_id': assetId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Verify Action
     * @param assetId
     * @param actionId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static verifyActionApiAssetsAssetIdActionsActionIdVerifyPost(
        assetId: string,
        actionId: number,
        requestBody: SetRetrofitStartDateModel,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/assets/{asset_id}/actions/{action_id}/verify',
            path: {
                'asset_id': assetId,
                'action_id': actionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Counterfactual Stats
     * @param retrofitId
     * @param assetId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getCounterfactualStatsApiAssetsAssetIdVerificationsRetrofitIdCounterFactualGet(
        retrofitId: string,
        assetId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/assets/{asset_id}/verifications/{retrofit_id}/counterFactual',
            path: {
                'retrofit_id': retrofitId,
                'asset_id': assetId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Asset Devices
     * @param assetId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getAssetDevicesApiAssetsAssetIdDevicesGet(
        assetId: string,
    ): CancelablePromise<Array<(PropertyModel | VehicleModel | LandModel)>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/assets/{asset_id}/devices',
            path: {
                'asset_id': assetId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Post Asset Device
     * @param assetId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static postAssetDeviceApiAssetsAssetIdDevicesPost(
        assetId: string,
        requestBody: DeviceModel,
    ): CancelablePromise<(PropertyModel | VehicleModel | LandModel)> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/assets/{asset_id}/devices',
            path: {
                'asset_id': assetId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
