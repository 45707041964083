/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AssetStatusEnum } from './AssetStatusEnum';
import type { AssetTagsEnum } from './AssetTagsEnum';
import type { LandTypeEnum } from './LandTypeEnum';

export type LandModel = {
    assetTags?: Array<AssetTagsEnum>;
    stats?: any;
    monthly_stats?: any;
    createdOn?: string;
    changedOn?: string;
    deletedOn?: string;
    status?: AssetStatusEnum;
    assetType: LandModel.assetType;
    originalLandType: LandTypeEnum;
    landId: string;
    tenure: string;
    areaSqM: number;
    _id?: string;
};

export namespace LandModel {

    export enum assetType {
        LAND = 'land',
    }


}

