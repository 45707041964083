/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum EnergySourceEnum {
    GRID_GAS = 'grid_gas',
    GRID_ELECTRICITY = 'grid_electricity',
    GRID_HEAT = 'grid_heat',
    GRID_WATER = 'grid_water',
    OFFGRID_OIL = 'offgrid_oil',
    OFFGRID_GAS = 'offgrid_gas',
}
