import { FC, PropsWithChildren } from "react";

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useAppContext } from './app-context';

interface RecaptchaProps { }

const Recaptcha: FC<PropsWithChildren<RecaptchaProps>> = (props) => {
    const appContext = useAppContext();
    
    return <GoogleReCaptchaProvider
      reCaptchaKey={appContext.settings.REACT_APP_RECAPTCHA_SITE_KEY}
      useEnterprise={true}>
        {props.children}
    </GoogleReCaptchaProvider>
}

export default Recaptcha;