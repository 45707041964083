/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum AssetTagsEnum {
    DWELLING = 'dwelling',
    VEHICLE = 'vehicle',
    LAND = 'land',
    GENERATION = 'generation',
}
