import { FC, useMemo, useState } from 'react';

import Typography from '@mui/material/Typography';
import { Button, Grid } from '@mui/material';

import BoltIcon from '@mui/icons-material/Bolt'
import FlameIcon from '@mui/icons-material/LocalFireDepartment'
import ClockIcon from '@mui/icons-material/Schedule'


import { EnergySourceEnum, VerificationObjectiveModel } from '../../api/cl-client';
import { LinearProgressWithLabel } from '../linear-progress-with-label';

interface VerificationProgressIndicatorProps {
    verificationObjective: VerificationObjectiveModel
    propertyStats: any
}


const VerificationProgressIndicator: FC<VerificationProgressIndicatorProps> = (props) => {
    const verificationObjective = props.verificationObjective

    const [projectedSavingsReached, setProjectedSavingsReached] = useState(false)

    const sourcesProgress = useMemo(() => {
        let projectedSavingsReached = true
        const result = verificationObjective.counterFactual?.sources?.map(item => {
            const objective = verificationObjective.energyDeltas?.find(delta => delta.sourceId === item.source)
            if (objective) {
                projectedSavingsReached = projectedSavingsReached && item.totalSavings >= (objective.value - objective.error)
                let savingsPercentage = Math.abs(item.totalSavings / (objective.value - objective.error) * 100)
                if (!isFinite(savingsPercentage)) {
                    savingsPercentage = 100
                }
                return <LinearProgressWithLabel value={savingsPercentage} color={item.source === EnergySourceEnum.GRID_ELECTRICITY ? "electricity" : "gas" } icon={item.source === EnergySourceEnum.GRID_ELECTRICITY ? <BoltIcon /> : <FlameIcon />} />
            } else {
                return null
            }
        })
        setProjectedSavingsReached(projectedSavingsReached)
        return result;
    }, [verificationObjective.counterFactual, verificationObjective.energyDeltas, setProjectedSavingsReached])

    const stats = props.propertyStats

    // const energyReductionValuePercentage = verificationObjective.counterFactual?.percentSavings ? verificationObjective.counterFactual?.percentSavings / 100 : 0

    const percentageSavings = verificationObjective.counterFactual?.percentSavings ? verificationObjective.counterFactual?.percentSavings : 0
    const costReduction = verificationObjective.counterFactual?.baselineStats['energyCostGbp'] - stats.energyCostGbp
    const carbonReduction = (verificationObjective.counterFactual?.baselineStats['co2G'] - stats.co2G) / 1000
    // const deltaTemperature = verificationObjective.counterFactual?.baselineStats['temperatureAlignment'] - stats.temperatureAlignment
    const virtualEpcChanged = verificationObjective.counterFactual?.baselineStats['virtualEpcEerClass'] !== stats.virtualEpcEerClass

    const now = new Date()
    const startDate = Date.parse(verificationObjective.startDate as string)
    const daysPassed = Math.round((now.getTime() - startDate) / (1000 * 60 * 60 * 24))
    const progressPercentage = 100 * daysPassed / 365
    
    return (
        <Grid container>
            <Grid item xs={6}>
                <>
                    <Typography>Verification progress</Typography>
                    <LinearProgressWithLabel value={progressPercentage} icon={<ClockIcon />} />
                    <Typography>Metered Savings</Typography>
                    {sourcesProgress}
                </>
            </Grid>
            <Grid item xs={6}>
                <Grid>
                    <Grid item xs={12} display="flex" direction="row" justifyContent="space-between" padding="0 0.5em">
                        <Typography width="70%">Energy Reduction</Typography>
                        <Typography width="30%" fontWeight={600}>{Math.abs(percentageSavings).toLocaleString('en-GB', {style: "percent"})}</Typography>
                    </Grid>
                    <Grid item xs={12} display="flex" direction="row" justifyContent="space-between" padding="0 0.5em">
                        <Typography width="70%">Cost Reduction</Typography>
                        <Typography width="30%" fontWeight={600}>{Math.abs(costReduction).toLocaleString('en-GB', {maximumSignificantDigits: 2, currency: 'GBP', style: "currency"})}</Typography>
                    </Grid>
                    <Grid item xs={12} display="flex" direction="row" justifyContent="space-between" padding="0 0.5em">
                        <Typography width="70%">Carbon Reduction</Typography>
                        <Typography width="30%" fontWeight={600}>{Math.abs(carbonReduction).toLocaleString('en-GB', {maximumFractionDigits: 1, style: "unit", unit: "kilogram"})}</Typography>
                    </Grid>
                    <Grid item xs={12} display="flex" direction="row" justifyContent="space-between" padding="0 0.5em">
                        <Typography width="70%">CLEVR™</Typography>
                        <Typography width="30%" fontWeight={600}>{virtualEpcChanged ? `from ${verificationObjective.counterFactual?.baselineStats['virtualEpcEerClass']} to ${stats.virtualEpcEerClass}` : stats.virtualEpcEerClass}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            { progressPercentage >= 100 &&
                <Button color="primary" >Download Savings Certificate</Button>
            }
            { progressPercentage >= 100 && !projectedSavingsReached &&
                <Button color="error" variant='outlined' >Claim Insurance Cover</Button>
            }
            {//<Grid item xs={4}>
             //   <Stack direction="column" spacing={2} justifyContent="space-around">
             //       <div>
             //           <Typography>Total carbon saved</Typography>
             //           <Typography fontWeight={600}>{Math.round(energyReductionValuePercentage * stats.co2G / 1000 / 21)} Trees</Typography>
             //           <Typography fontWeight={600}>{Math.round(energyReductionValuePercentage * stats.co2G / 1000 /  0.2214)} Car miles</Typography>
             //       </div>
             //       <div>
             //           <Typography>Temperature reduction</Typography>
             //           <Typography fontWeight={600}>{deltaTemperatureString}</Typography>
             //       </div>
             //   </Stack>
             //   </Grid>
            }
        </Grid>
    )
};

export default VerificationProgressIndicator
