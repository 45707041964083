import { UkAddress } from '@ideal-postcodes/core-axios';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Paper, Typography, Checkbox, FormControlLabel, Stack, Tooltip } from '@mui/material';
import { FC } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { AddressModel, DemoPropertyModel, DemoService } from '../../api/cl-client';
import AddressLookup from '../../components/address-lookup';
import Footer from '../../components/footer';
import { useAppContext } from '../../components/app-context';


interface IPropertyForm extends DemoPropertyModel {
}

const Landing: FC = () => {
    const appContext = useAppContext();

    const navigate = useNavigate();

    const { executeRecaptcha } = useGoogleReCaptcha();

    const { register, handleSubmit, setValue, formState: { isSubmitting, isValid, isDirty } } = useForm<IPropertyForm>();

    if (!executeRecaptcha || appContext.settings.REACT_APP_RECAPTCHA_SITE_KEY === undefined) {
        return null;
    }

    const onSubmit = async (data: IPropertyForm) => {
        // for debugging locally
        //if (data.address === undefined) {
        //    data.address = defaultAddress;
        //}

        if(executeRecaptcha) {
            const token = await executeRecaptcha('cl_demo_submit');

            const property: DemoPropertyModel = {
                ...data,
                isDemoProperty: true
            }
            const newProperty  = await DemoService.postAssetApiDemoPost(token, property)
            navigate(`demos/${newProperty._id}`);
        } else {
            console.error("Recaptcha not loaded");
        }
    }

    const onAddressChanged = (address: UkAddress | null) => {
        if (address) {
            const newValue: AddressModel = {
                uprn: +address.uprn,
                address1: address.line_1,
                address2: address.line_2,
                postcode: address.postcode,
                city: address.post_town,
                country: address.country,
            }
            if (address.longitude && address.latitude) {
                newValue.longitude = address.longitude;
                newValue.latitude = address.latitude;
            }
            setValue('address', newValue, {shouldDirty: true, shouldValidate: true})
        }
    }
    
    const isLoading = isSubmitting
    const isDisabled = !isValid || !isDirty

    return ( 
        <>
            <Box sx={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                backgroundImage: "url('/leaf-background.jpg')",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundAttachment: "fixed",
                justifyContent: "center",
                alignItems: 'center',
                }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Paper sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        maxWidth: '600px',
                        padding: '20px',
                        '> *:not(:last-child)': { marginBottom: '1em' }
                    }}>
                        <Typography variant='h5' color="primary">The verifiable journey to Net Zero</Typography>
                        <Typography variant='body2'>Energy and Carbon Emissions tracking is challenging. Jaw-dropping Energy Prices with New rules and Incentives make things more complex and confusing. It doesn't have to be that way. Energy and Carbon Savings and Efficiencies can save money while doing your bit for the planet.</Typography>
                        <Typography variant='body2'>CarbonLaces built this app to deliver Personalised, Simple and Transparent Energy profiles with Suggested actions to help you Save Energy, Reduce Carbon and Lower your Home's running costs while doing your bit for the Planet with real Energy Data and Machine Learning.</Typography>
                        <Typography variant='body2'>Please give it a whirl.</Typography>
                        <Stack>
                            <AddressLookup id={0} onAddressChanged={onAddressChanged} />
                            <Tooltip title="If you would like to get a start with some basic action choose this.">
                                <FormControlLabel control={<Checkbox {...register("minimalBudget")} />} label="Minimal renovation budget" />
                            </Tooltip>
                            {isLoading && <Typography id="demo-form-submitted-message" variant='subtitle2' color="primary">We are generating your property preview, please be patient.</Typography>}
                            <LoadingButton id="demo-form-submit-button" type='submit' variant="contained" disabled={isDisabled} loading={isLoading}>Show solutions for property</LoadingButton>
                        </Stack>
                        <Paper style={{
                            padding: '0 1em',
                            border: 'solid 1px #007045'
                        }}>
                            <Typography variant='subtitle2' style={{fontWeight: 600}}>
                                CarbonLaces is releasing anonymised dataset based on half hourly residential energy consumption data to find out more:&nbsp;
                                <a href='https://carbonlaces.com/datasets'>https://carbonlaces.com/datasets</a>
                            </Typography>
                        </Paper>
                    </Paper>
                </form>
            </Box>
            <Footer></Footer>
        </>);
}

export default Landing;