import { Box } from "@mui/material";
import {useEffect, useRef} from "react";

const DemoSignupForm = () => {
    const form = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const script = document.createElement('script');
        script.src='https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        const listener = () => {
            // @ts-ignore
            if (window.hbspt) {
                // console.log("Rendering form");
                // @ts-ignore
                window.hbspt.forms.create({
                    region: "eu1",
                    portalId: '26258065',
                    formId: '710f236d-2a46-4848-8d6e-c546c7f70df2',
                    target: '#demo-signup-form',
                    redirectUrl: null
                })
            }
        }

        const submissionListener = (event: any) =>  {
            if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady') {
                if (event.data.id === '710f236d-2a46-4848-8d6e-c546c7f70df2')
                {
                    // console.log("Form is ready", event)
                    const iFrames = document.getElementsByClassName("hs-form-iframe")
                    if (iFrames.length > 0) {
                        const iFrameDocument = (iFrames[0] as HTMLIFrameElement).contentDocument
                        if (iFrameDocument) {
                            const pageUrlInputs = iFrameDocument.getElementsByName("page_url")
                            if (pageUrlInputs.length > 0) {
                                const input = pageUrlInputs[0] as HTMLInputElement
                                input.value = window.location.href
                                const e = new Event('input',{bubbles:true})
                                input.dispatchEvent(e)
                            }
                        }
                    }
                }
            }
        }

        script.addEventListener('load', listener);
        window.addEventListener('message', submissionListener);

        return () => {
            script.removeEventListener('load',listener)
            window.removeEventListener('message', submissionListener);
        };
    }, []);

    return (
        <Box id="demo-signup-form" ref={form} width="100%" />
    );

}

export default DemoSignupForm;