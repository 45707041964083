/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum InterestedAssetTypes {
    PROPERTY = 'property',
    VEHICLE = 'vehicle',
    LAND = 'land',
    GENERATION = 'generation',
    OTHER = 'other',
}
