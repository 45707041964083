import { Paper, Typography } from '@mui/material';
import React, { FC, useEffect, useMemo } from 'react';

export const GoogleAds: FC = () => {
    const isDevEnvironment = useMemo(() => process.env.NODE_ENV === "development", [])
  
    useEffect(() => {
        if (!isDevEnvironment) {
            // The dev environment has strict mode enabled causing this component to push twice which results in an exception
            ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({});
        }
    }, [isDevEnvironment])

    if (isDevEnvironment) {
        return (
            <Paper style={{width: '100%', height: '250px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <Typography>Google add would be displayed here</Typography>
            </Paper>
        )
    }
    else 
    {
        return (
            <ins className="adsbygoogle"
                style={{display: 'block'}}
                data-ad-client="ca-pub-5558186775676303"
                data-ad-slot="9996423256"
                data-ad-format="auto"
                data-full-width-responsive="true" />
        );
    }
}