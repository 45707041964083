/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DemoPropertyModel } from '../models/DemoPropertyModel';
import type { FollowupRequestModel } from '../models/FollowupRequestModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DemoService {

    /**
     * Post Asset
     * @param recaptchaToken
     * @param requestBody
     * @returns DemoPropertyModel Successful Response
     * @throws ApiError
     */
    public static postAssetApiDemoPost(
        recaptchaToken: string,
        requestBody: DemoPropertyModel,
    ): CancelablePromise<DemoPropertyModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/demo',
            headers: {
                'recaptcha-token': recaptchaToken,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Asset
     * @param assetId
     * @returns DemoPropertyModel Successful Response
     * @throws ApiError
     */
    public static readAssetApiDemoAssetIdGet(
        assetId: string,
    ): CancelablePromise<DemoPropertyModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/demo/{asset_id}',
            path: {
                'asset_id': assetId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Post Followup Request
     * @param assetId
     * @param recaptchaToken
     * @param requestBody
     * @returns FollowupRequestModel Successful Response
     * @throws ApiError
     */
    public static postFollowupRequestApiDemoAssetIdFollowupsPost(
        assetId: string,
        recaptchaToken: string,
        requestBody: FollowupRequestModel,
    ): CancelablePromise<FollowupRequestModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/demo/{asset_id}/followups',
            path: {
                'asset_id': assetId,
            },
            headers: {
                'recaptcha-token': recaptchaToken,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
