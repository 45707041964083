import { PafAddress, UkAddress } from "@ideal-postcodes/core-axios";
import { AddressModel, PropertyModel } from "../api/cl-client";

export function toAddressModel(newAddress: UkAddress | PafAddress) : AddressModel {
    return {
        uprn: Number(newAddress.uprn),
        address1: newAddress.line_1,
        address2: newAddress.line_2,
        city: newAddress.post_town,
        postcode: newAddress.postcode,
        longitude: newAddress.longitude ? newAddress.longitude : undefined,
        latitude: newAddress.latitude ? newAddress.latitude : undefined,
        country: newAddress.country,
    }
}

export function toPropertyModel(config: {address: UkAddress | PafAddress, totalFloorArea?: number}) {
    const mappedConfig: PropertyModel = {address: toAddressModel(config.address)}
    if (config.totalFloorArea && config.totalFloorArea > 0) {
        mappedConfig.configOverride = {
            totalFloorArea: config.totalFloorArea,
        }
    }
    return mappedConfig
}