import { TextField, Grid } from '@mui/material';
import { FC, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { AssetsService, AssetTypeEnum, LandModel, PropertyModel, VehicleModel } from '../api/cl-client';
import PostcodeLookup from './postcode-lookup';
import { PafAddress } from '@ideal-postcodes/core-axios';
import PropertyMap from './property-map';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { toAddressModel } from '../utils/mappers';

interface AssetFormPropertyProps {
    asset?: PropertyModel;
    onAssetChange?: (property: PropertyModel) => void;
}

const AssetFormProperty: FC<AssetFormPropertyProps> = (props) => {
    const navigate = useNavigate();

    const { mutate, isLoading, isSuccess } = useMutation(AssetsService.postAssetApiAssetsPost);

    const queryClient = useQueryClient();

    const { control, handleSubmit, setValue, getValues, formState:{ errors, isValid } } = useForm<PropertyModel>();
    const onSubmit = (data: PropertyModel) => {
        mutate(data, {
            onSuccess: (item) => {
                queryClient.invalidateQueries('assets');
                navigate('/assets/' + (item as PropertyModel | VehicleModel | LandModel)._id);
            }
        })

    }

    function onAddressChange(newAddress: PafAddress) {
        const address = toAddressModel(newAddress)
        setValue('address', address, { shouldValidate: true });
    }

    useEffect(() => {
        setValue('assetType', AssetTypeEnum.PROPERTY);
    })

    // console.log(getValues());
    // console.log(getValues('address.latitude'))

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Grid container spacing={0} direction="column">
                            <PostcodeLookup onAddressChanged={(address) => onAddressChange(address)} />
                            <Controller
                                name="address.address1"
                                control={control}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        {...field} 
                                        label="Address 1"
                                        variant="standard" 
                                        disabled
                                        error={errors.address?.address1 ? true : false} 
                                        helperText={errors.address?.address1?.message} />}
                            />
                            <Controller
                                name="address.address2"
                                control={control}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        {...field} 
                                        label="Address 2"
                                        variant="standard" 
                                        disabled
                                        error={errors.address?.address2 ? true : false} 
                                        helperText={errors.address?.address2?.message} />}
                            />
                            <Grid container>
                                <Grid item xs={6}>
                                    <Controller
                                        name="address.postcode"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => 
                                            <TextField 
                                                {...field}
                                                label="Postcode"
                                                variant="standard" 
                                                disabled
                                                error={errors.address?.postcode ? true : false} 
                                                helperText={errors.address?.postcode?.message} />}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        name="address.city"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => 
                                            <TextField 
                                                {...field} 
                                                label="City"
                                                variant="standard" 
                                                disabled
                                                error={errors.address?.city ? true : false} 
                                                helperText={errors.address?.city?.message} />}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <PropertyMap lat={getValues('address.latitude')} lng={getValues('address.longitude')} zoom={18} />
                    </Grid>
                </Grid>
                <LoadingButton type="submit" variant="contained" color="primary" loading={isLoading} disabled={isLoading || !isValid || isSuccess}>Save</LoadingButton>
            </form>
        </>
    );
}

export default AssetFormProperty;