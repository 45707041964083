import { FC, useCallback, useEffect, useState } from 'react';

import {useDropzone} from 'react-dropzone'

import Typography from '@mui/material/Typography';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Button, Paper, Box } from '@mui/material';

import Papa, {ParseResult} from "papaparse";
import AddressLookup from '../../components/address-lookup';
import { UkAddress } from '@ideal-postcodes/core-axios';
import { toPropertyModel } from '../../utils/mappers';
import { useMutation } from 'react-query';
import { AssetsService } from '../../api/cl-client';
import { useNavigate } from 'react-router-dom';

interface IUploadCsvProps {
}

interface ICsv {
    Address: string;
    floor_space_m2?: number;
}

const UploadCsv: FC<IUploadCsvProps> = () => {

    const [parsedCsv, setParsedCsv] = useState<ICsv[]>([])

    const [tableRows, setTableRows] = useState<any[]>([])

    const [configs, setConfigs] = useState<{address: UkAddress, totalFloorArea?: number}[]>([])
    
    const { mutate, isLoading, isSuccess } = useMutation(AssetsService.postAssetApiAssetsPost);

    const navigate = useNavigate();

    const onFileParsed = useCallback((result: ParseResult<ICsv>) => {
    

        // console.log(result)
        setParsedCsv(result.data)
    }, [])

    const onDrop = useCallback((acceptedFiles: File[]) => {
        // console.log(acceptedFiles)// Do something with the files

        // Parse CSV file
        if (acceptedFiles.length) {
            Papa.parse(acceptedFiles[0], {
                header: true,
                skipEmptyLines: true,
                complete: onFileParsed,
                transformHeader: (header) => {
                    return header.trim();
                },
                transform: (value, column) => {
                    if (column === 'Address') {
                        // Trim values in the Address column
                        return value.trim();
                    } else if (column === 'floor_space_m2') {
                        // Convert to a number and set undefined if not valid or below 1
                        const numValue = Number(value.trim());
                        return isNaN(numValue) || numValue < 1 ? undefined : numValue;
                    }
                    return value.trim();
                },
            });
        }

    }, [onFileParsed])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, multiple: false, accept: {'text/csv': ['.csv']}})

    const onAddressChanged = useCallback((address: UkAddress | null, id: number) => {
        // console.log("Address set", id, address)
        if (address) {
            setConfigs(currentConfigs => {
                const configs = [...currentConfigs]
                configs[id] = {address, totalFloorArea: parsedCsv[id].floor_space_m2}
                return configs
            })
        }
    }, [setConfigs, parsedCsv])
    
    useEffect(() => {
        const batchSize = 10
        const batchCount = Math.ceil(parsedCsv.length / batchSize);
        const timers: NodeJS.Timeout[] = []
        setTableRows([])
        setConfigs([])
        for(let batch = 0; batch < batchCount; batch++) {
            timers.push(setTimeout(() => {
                for(let rowIndex = batch * batchSize; rowIndex < Math.min((batch + 1) * batchSize, parsedCsv.length); rowIndex++){
                    const row = parsedCsv[rowIndex]
                    setTableRows(previous => [
                        ...previous,
                        <TableRow
                            key={rowIndex}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell>{row.Address}</TableCell>
                            <TableCell>
                                <AddressLookup id={rowIndex} address={row.Address} onAddressChanged={onAddressChanged} selectFirstBest/>
                            </TableCell>
                            <TableCell>
                                {row.floor_space_m2}
                            </TableCell>
                        </TableRow>
                    ])                    
                }
            }, 1000 * batch))
        }
        return () => {
            for(let timer of timers) {
                clearTimeout(timer)
            }
        }
    }, [parsedCsv, onAddressChanged, setTableRows])

    const onSubmit = useCallback(() => {
        const properties = configs.map((config) => toPropertyModel(config))
        
        // needed to cast to any as the typescript api generator provides wrong signature
        mutate(properties as any, {onSuccess: (data) => {
            navigate('/assets')
        }})
    }, [configs, mutate, navigate])

    return (
        <>
            <Typography variant='h4' color='primary'>Upload csv</Typography>
            <Typography>This currently only works for property assets. An example CSV file that shows the required format can be downloaded <a href="https://clblobstore.blob.core.windows.net/public/batch-upload-tempate/property_sample_with_floor_space.csv">here</a>.</Typography>
            
            {parsedCsv.length === 0 && 
                <Box {...getRootProps()} 
                    sx={{
                        padding: "10px",
                        margin: "10px",
                        border: "2px dashed #999",
                        borderRadius: "15px",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        minHeight: "50px"
                    }}>
                    <input {...getInputProps()} />
                    {
                        isDragActive ?
                        <Typography>Drop the files here ...</Typography> :
                        <Typography>Drag 'n' drop some files here, or click to select files</Typography>
                    }
                </Box>}
            {parsedCsv.length > 0 && <>
                <Typography>Inputs</Typography>
            
                <TableContainer component={Paper}>
                    <Table aria-label="simple table" size="small" >
                        <TableHead>
                            <TableRow>
                                <TableCell width="25%">Address</TableCell>
                                <TableCell width="50%">Address lookup</TableCell>
                                <TableCell width="100px">Floor Space (m2)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableRows}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button variant='outlined' color='error' onClick={() => setParsedCsv([])} disabled={parsedCsv.length === 0}>Clear rows</Button>
                <Button variant='contained' onClick={onSubmit} disabled={isLoading || isSuccess || tableRows.length !== parsedCsv.length}>Save Assets</Button>
            </>}
        </>
    );
}

export default UploadCsv;