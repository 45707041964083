import { Grid, Paper, Typography, Stack, Table, TableBody, TableRow, TableCell, TableHead, useTheme } from "@mui/material";
import { FC, useState, useMemo } from "react";
import KeyPerformanceIndicator from "./key-performance-indicator";
import { VehicleModel } from "../api/cl-client";
import { ResponsiveContainer, CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis, Label } from "recharts"
import { getKeys } from "../utils/typescript";

const data = [
    {
      "name": "May 2021",
      "kilometers": Math.random() * 400,
    },
    {
      "name": "Jun 2021",
      "kilometers": Math.random() * 400,
    },
    {
      "name": "Jul 2021",
      "kilometers": Math.random() * 400,
    },
    {
      "name": "Aug 2021",
      "kilometers": Math.random() * 400,
    },
    {
      "name": "Sep 2021",
      "kilometers": Math.random() * 400,
    },
    {
      "name": "Oct 2021",
      "kilometers": Math.random() * 400,
    },
    {
      "name": "Nov 2021",
      "kilometers": Math.random() * 400,
    },
    {
      "name": "Dec 2021",
      "kilometers": Math.random() * 400,
    },
    {
      "name": "Jan 2022",
      "kilometers": Math.random() * 400,
    },
    {
      "name": "Feb 2022",
      "kilometers": Math.random() * 400,
    },
    {
      "name": "Mar 2022",
      "kilometers": Math.random() * 400,
    },
    {
      "name": "Apr 2022",
      "kilometers": Math.random() * 400,
    }
  ]

interface AssetViewVehicleProps {
    asset: VehicleModel
}

const risk = 25 + Math.random() * 50

const Vehicle: FC<AssetViewVehicleProps> = (props) => {
    const theme = useTheme();

    const [showGraph, setShowGraph] = useState(false);

    const co2Emissions = props.asset.co2Emissions ? props.asset.co2Emissions : 250;

    const certificates = []
    const retrofits = []
    if (props.asset._id === '6d4e0b7f-82a9-4df1-9089-92af7cd67c3f') {
        for (let i = 4; i < 12+4; i++) {
            const carbonAmount = Math.sin(Math.abs((i % 12) - 6) / 6 ) * 243.24 + 23.24;
            const carbonValue = carbonAmount * 240.95 / 1000;
            certificates.push({
                type: `Verra Carbon Credit - VM0008`,
                date: (new Date(2021 + Math.floor(i / 12), (i%12), 1)).toLocaleDateString(),
                amount: `${carbonAmount.toFixed(2)} kg`,
                value: `£${carbonValue.toFixed(2)}`
            })
        }

        retrofits.push({
            type: 'Heat pump installation',
            value: '£13,800',
            date: '17/12/2021',
            status: 'pending',
            deRiskScore: 46
        })
    }

    const registrationData = useMemo(() => {
        if (props.asset) {
            return getKeys<VehicleModel>(props.asset).sort().map((key) => (
                <TableRow>
                    <TableCell>{key}</TableCell>
                    <TableCell>{JSON.stringify(props.asset[key])}</TableCell>
                </TableRow>
            ))
        }
    }, [props.asset]);

    return (
    <>
        <Grid container spacing={1}>
            <Grid item xs={12} > 
                <Paper>
                    <Typography variant='h5'>{props.asset.registrationNumber}, {props.asset.make}</Typography>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Stack spacing={1} >
                    <Grid container spacing={2}> 
                        <Grid item xs={4} display="flex" onClick={() => setShowGraph(!showGraph)}><KeyPerformanceIndicator name="CO₂ Risk Score" value={risk.toFixed(0)} backgroundColor="#fcba03" /></Grid>
                        <Grid item xs={4} display="flex" onClick={() => setShowGraph(!showGraph)}><KeyPerformanceIndicator name="Temperature" value={(risk / 100 * 7.8).toFixed(1)} unit='°C' /></Grid>
                        <Grid item xs={4} display="flex" onClick={() => setShowGraph(!showGraph)}><KeyPerformanceIndicator name="CO₂" value={data.reduce((prev, current) => prev += current.kilometers * co2Emissions / 1000, 0).toFixed(0)} unit='kg/year' /></Grid>
                    </Grid>
                    {showGraph ? <ResponsiveContainer width="100%" height={200}>
                        <LineChart width={730} height={250} data={data}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis yAxisId="left" >
                                <Label angle={270} value="Score" position="left" style={{textAnchor: 'middle'}}/>
                            </YAxis>
                            <YAxis yAxisId="right" orientation="right">
                                <Label angle={270} value="kgCO₂/m²/year" position="right" style={{textAnchor: 'middle'}} />
                            </YAxis>
                            <Tooltip />
                            <Legend />
                            <Line yAxisId="left" type="monotone" dataKey="risk" name="CO₂ Risk Score" stroke="#fcba03" />
                            <Line yAxisId="right" type="monotone" dataKey="ci" name="Carbon Intensity" stroke={theme.palette.primary.dark} />
                        </LineChart>
                    </ResponsiveContainer> : null}
                    <Paper>
                        <Typography variant='h6'>Details</Typography>
                        <Grid item xs={12}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Property</TableCell>
                                        <TableCell>Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {registrationData}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Paper>
                </Stack>
            </Grid>
        </Grid>
    </>)
}

export default Vehicle;