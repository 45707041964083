import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import type { RootState } from '../store'

import { UserModel, UserService } from '../../api/cl-client';

const initialState: UserModel = {
    name: '',
}


export const fetchMe = createAsyncThunk(
  'users/fetchMe',
  async () => {
    const response = await UserService.readMeApiUsersMeGet()
    return Promise.resolve(response)
  }
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<UserModel>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state = { ...state, ...action.payload }
    },
    clear: (state) => {
      state = initialState
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchMe.fulfilled, (state, action) => {
      // Add user to the state array
      // console.log(action)
      state = { ...action.payload }
      // console.log(state)
      return state
    })
  },
})

export const { update, clear } = userSlice.actions

// SELECTORS
export const selectUserDetails = (state: RootState) => ({
  id: state.user._id,
  name: state.user.name,
  email: state.user.email
})

export default userSlice.reducer
