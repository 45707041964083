import './App.scss';
import { DashboardLayout } from './components/dashboard-layout';
import { Routes, Route } from 'react-router-dom';

import DisclaimerPage from './pages/legal/disclaimer';
import LandingPage from './pages/demo/landing';
import OutcomesPage from './pages/demo/outcomes';
import ViewAssetsPage from './pages/assets/list';
import EditAssetsPage from './pages/assets/edit';
import ViewAssetPage from './pages/assets/item';
import DashboardLandingPage from './pages/dashboard/landing';
import EditAssetDevicePage from './pages/assetDevices/edit';
import { useUser } from './components/user-context';
import Recaptcha from './components/recaptcha';
import UploadCsv from './pages/assets/uploadCsv';

function App() {
  
  const auth = useUser();

  return (
      <DashboardLayout>
        <Routes>
          <Route path="/" element={auth.isAuthenticated ? <DashboardLandingPage /> : <Recaptcha><LandingPage /></Recaptcha>}/>
          <Route path="/demos/:assetId" element={<Recaptcha><OutcomesPage /></Recaptcha>}/>
          <Route path="/assets" element={<ViewAssetsPage />} />
          <Route path="/assets/post" element={<EditAssetsPage />} />
          <Route path="/assets/postCsv" element={<UploadCsv />} />
          <Route path="/assets/:assetId" element={<ViewAssetPage />} />
          <Route path="/assets/:assetId/devices/post" element={<EditAssetDevicePage />} />
          <Route path="/disclaimer" element={<DisclaimerPage />} />
        </Routes>
      </DashboardLayout>
  );
}

export default App;
