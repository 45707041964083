/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum AssetStatusEnum {
    GENERATING = 'generating',
    DONE = 'done',
}
