/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AssetStatusEnum } from './AssetStatusEnum';
import type { AssetTagsEnum } from './AssetTagsEnum';
import type { MotStatusEnum } from './MotStatusEnum';
import type { TaxStatusEnum } from './TaxStatusEnum';

export type VehicleModel = {
    assetTags?: Array<AssetTagsEnum>;
    stats?: any;
    monthly_stats?: any;
    createdOn?: string;
    changedOn?: string;
    deletedOn?: string;
    status?: AssetStatusEnum;
    assetType: VehicleModel.assetType;
    registrationNumber: string;
    taxStatus?: TaxStatusEnum;
    taxDueDate?: string;
    artEndDate?: string;
    motStatus?: MotStatusEnum;
    motExpiryDate?: string;
    make?: string;
    monthOfFirstDvlaRegistration?: string;
    monthOfFirstRegistration?: string;
    yearOfManufacture?: number;
    engineCapacity?: number;
    co2Emissions?: number;
    fuelType?: string;
    markedForExport?: boolean;
    colour?: string;
    typeApproval?: string;
    wheelplan?: string;
    revenueWeight?: number;
    realDrivingEmissions?: string;
    dateOfLastV5CIssued?: string;
    euroStatus?: string;
    _id?: string;
};

export namespace VehicleModel {

    export enum assetType {
        VEHICLE = 'vehicle',
    }


}

