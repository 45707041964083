/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum FeatureInterestEnum {
    CARBON_ACCOUNTING = 'carbon_accounting',
    CARBON_CREDITS = 'carbon_credits',
    CARBON_RISK_ANALYSIS = 'carbon_risk_analysis',
    OTHER = 'other',
}
