import { Typography, Paper, Box, Button, Stack, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Dialog } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC, useEffect, useMemo, useState } from 'react';
import { AssetsService, PackageItemsEnum, PropertyModel } from '../../api/cl-client';
import { useLocation } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMutation, useQueryClient } from 'react-query';

interface ActionDetailsProps {
    asset: PropertyModel;
    action: any;
    action_id: number;
    onContactClick?: () => void;
    onViewIncentiveClick?: () => void;
}

const ActionDetails: FC<ActionDetailsProps> = (props: ActionDetailsProps) => {
    const itemId = useMemo(() => props.asset._id + "-" + props.action.items.join('_'), [props.asset._id, props.action.items]);

    const [dismissed, setDismissed] = useState(JSON.parse(localStorage.getItem(itemId) ?? "false"));

    const [verificationDialogOpen, setVerificationDialogOpen] = useState(false)

    const [startDate, setStartDate] = useState<Date | null>(null);
    
    const queryClient = useQueryClient();

    const location = useLocation()

    const isDemo = useMemo(() => ( location ? location.pathname.startsWith('/demos') : true ), [location])

    const retrofitStartMutator = useMutation(
        (startDate: Date) => AssetsService.verifyActionApiAssetsAssetIdActionsActionIdVerifyPost(
            props.asset._id as string, 
            props.action_id, 
            { date: startDate.toISOString().slice(0, 10) }),
            {
                onSuccess: (item) => {
                    queryClient.invalidateQueries(['/assets/' + props.asset._id]);
                    setVerificationDialogOpen(false);
                }
            })

    useEffect(() => {
        localStorage.setItem(itemId, JSON.stringify(dismissed));
    }, [dismissed, itemId])

    // console.log(props);

    const theme = useTheme();

    const isEcoCandidate = props.asset.config.imdIncomeDecile && props.asset.config.imdHousingDecile ? props.asset.config.imdHousingDecile < 2 && props.asset.config.imdHousingDecile < 5 : true;
    const packageWithBoilerUpgrade = props.action.items.includes(PackageItemsEnum.HEAT_PUMP)
    const insulationItemIndex = props.action.items.indexOf(PackageItemsEnum.INSULATION)
    const packageWithInsulation = insulationItemIndex > -1
    const insulationCost = packageWithInsulation && isEcoCandidate ? props.action.metaList[insulationItemIndex]['cost_gbp'] : 0

    const energyCostSavingsGbp = props.asset.stats.energyCostGbp - props.action.stats.energyCostGbp

    const upfontCostMax = props.action.totalCostGbp
    const paybackPeriodMax = Math.ceil(upfontCostMax / energyCostSavingsGbp)
    const upfrontCostMin = upfontCostMax - (packageWithBoilerUpgrade ? 6000 : 0) - insulationCost
    const paybackPeriodMin = Math.ceil(upfrontCostMin / energyCostSavingsGbp)


    if (dismissed) {
        return null
    }

    return (
    <>
        <Box display="flex" flexDirection="row" textAlign="center" sx={{width: "calc(100% - 24px)"}}>
            <Box width="20%"></Box>
            <Box width="20%">
                <Typography variant='body2'>Potential EPC</Typography>
                <Typography variant='body2' fontWeight={600}>
                    {props.asset.stats.virtualEpcEerClass} to&nbsp;
                    {props.action.stats.virtualEpcEerClass}
                </Typography>
            </Box>
            <Box width="20%">
                <Typography variant='body2'>Potential Temperature</Typography>
                <Typography variant='body2' fontWeight={600}>
                    {props.asset.stats.temperatureAlignment.toLocaleString('en-GB', {maximumFractionDigits: 1, style: "unit", unit: "celsius"})} to&nbsp;
                    {props.action.stats.temperatureAlignment.toLocaleString('en-GB', {maximumFractionDigits: 1, style: "unit", unit: "celsius"})}
                </Typography>
            </Box>
            <Box width="20%">
                <Typography variant='body2'>Potential Energy Saved</Typography>
                <Typography variant='body2' fontWeight={600}>{((props.asset.stats.consumptionKwh - props.action.stats.consumptionKwh) / props.asset.stats.consumptionKwh).toLocaleString('en-GB', {style: "percent", maximumFractionDigits: 0})}</Typography>
            </Box>
            {!isDemo && <Box width="20%">
                <Typography variant='body2'>Payback Period</Typography>
                <Typography variant='body2' fontWeight={600}>{paybackPeriodMin !== paybackPeriodMax ? paybackPeriodMin.toLocaleString('en-GB', {maximumFractionDigits: 1, style: "unit", unit: "year"}) + " to " : null}{paybackPeriodMax.toLocaleString('en-GB', {maximumFractionDigits: 1, style: "unit", unit: "year"})}</Typography>
            </Box>}
        </Box>
        <Typography fontWeight={600} color={theme.palette.primary.main}> Available grants </Typography>
        <Stack spacing={1}>
            {isEcoCandidate && packageWithInsulation && <Stack sx={{padding: '10px', alignItems: "center"}} direction="row" component={Paper} spacing={1}> 
                <Typography variant='body2' fontWeight="600" width="20%">ECO Grant</Typography>
                <Typography variant='body2' flex="1">Government gives grants, about £100M a year for FREE, you might qualify: Central Heating Installation, Cavity Wall Insulation, Wall Insulation, Boiler Installation and Electric Storage Heater Replacement
                </Typography>
                {props.onViewIncentiveClick && <Button variant="contained" color="primary" onClick={props.onViewIncentiveClick}> View </Button>}
            </Stack>}
            {packageWithBoilerUpgrade && <Stack sx={{padding: '10px', alignItems: "center"}} direction="row" component={Paper} spacing={1}> 
                <Typography variant='body2' fontWeight="600" width="20%">Boiler Upgrade Scheme</Typography>
                <Box  width="20%" textAlign="center">
                    <Typography variant='body2'>Up to</Typography>
                    <Typography variant='body2' fontWeight="600">£6000</Typography>
                </Box>
                <Typography variant='body2' flex="1">The government is providing grants to encourage property owners to install low carbon heating systems such as heat pumps, through the Boiler Upgrade Scheme (BUS).</Typography>
                {props.onViewIncentiveClick && <Button variant="contained" color="primary" onClick={props.onViewIncentiveClick}> View </Button>}
            </Stack>}
            {(!isEcoCandidate || !packageWithInsulation) && !packageWithBoilerUpgrade && <Stack sx={{padding: '10px', alignItems: "center"}} direction="row" component={Paper} spacing={1}> 
                <Typography variant='body2' fontWeight="100" textAlign="center" width="100%">No Grants Available</Typography>
            </Stack>}
            {isDemo && <Button variant="contained" color="primary" onClick={props.onContactClick}>For a comprehensive evaluation get in touch</Button>}
            {!isDemo && !props.action.verificationObjective?.startDate && <Button variant="contained" color="primary" onClick={() => setVerificationDialogOpen(true)}>Validate action</Button>}
            <Button variant="outlined" color="error" onClick={() => setDismissed(true)}>Dismiss action</Button>
        </Stack>
        <Dialog
            open={verificationDialogOpen}
            onClose={() => setVerificationDialogOpen(false)}>
                <DialogTitle>Applicable action details</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Overview of the proposed action for this asset.
                    </DialogContentText>
                    <Stack>
                        <Box>
                            <Typography>Select start date</Typography>
                            <DatePicker
                                label="Start date"
                                inputFormat="DD/MM/YYYY"
                                value={startDate}
                                onChange={(value: Date | null) => setStartDate(value)}
                                renderInput={(params: any) => <TextField {...params} variant="standard" />}
                            />
                        </Box>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <LoadingButton variant="contained" color="primary" sx={{marginTop: '1em'}} onClick={() => retrofitStartMutator.mutate(startDate as Date)} loading={retrofitStartMutator.isLoading}>Schedule verification</LoadingButton>
                </DialogActions>
        </Dialog>
    </>)
}

export default ActionDetails;