/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DnoStatsModel } from '../models/DnoStatsModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DnosService {

    /**
     * Read Dno Stats
     * @returns DnoStatsModel Successful Response
     * @throws ApiError
     */
    public static readDnoStatsApiDnosstatsGet(): CancelablePromise<Array<DnoStatsModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/dnosstats',
        });
    }

}
