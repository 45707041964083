import { Accordion, AccordionDetails, AccordionSummary, Typography, Box, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FC, useEffect, useMemo, useState } from 'react';
import { ActionModel, PropertyModel, VerificationObjectiveModel } from '../../api/cl-client';
import { useLocation } from 'react-router-dom';
import { getDeRisk } from '../../utils/asset';
import { startCase } from 'lodash';
import ActionDetails from './action-details';
import VerificationProgressIndicator from './verfication-progress';

interface ActionListItemProps {
    asset: PropertyModel;
    action: ActionModel;
    action_id: number;
    onContactClick?: () => void;
    onViewIncentiveClick?: () => void;
}

const ActionListItem: FC<ActionListItemProps> = (props: ActionListItemProps) => {
    const itemId = useMemo(() => props.asset._id + "-" + props.action.items.map(item => item.replace(/[^a-z0-9]/gi, '')).join('_'), [props.asset._id, props.action.items]);

    const [dismissed] = useState(JSON.parse(localStorage.getItem(itemId) ?? "false"));

    const location = useLocation()

    const isDemo = useMemo(() => ( location ? location.pathname.startsWith('/demos') : true ), [location])

    const isActiveAction = useMemo(() => !!props.action.verificationObjective?.startDate, [props.action])

    useEffect(() => {
        localStorage.setItem(itemId, JSON.stringify(dismissed));
    }, [dismissed, itemId])

    const theme = useTheme();

    const energyCostSavingsGbp = props.asset.stats.energyCostGbp - props.action.stats.energyCostGbp
    const energyCostSavingsPercent = energyCostSavingsGbp / props.asset.stats.energyCostGbp

    const derisk = getDeRisk(props.asset, props.action)
    const expandIcon = <ExpandMoreIcon 
        sx={{
            backgroundColor: theme.palette.primary.main,
            borderRadius: "50%",
            color: "#fff"
        }} />

    if (dismissed) {
        return null
    }

    return (
    <Accordion 
        id={`accordion_${itemId}`}>

        <AccordionSummary
            id={`accordion_summary_${itemId}`}
            expandIcon={expandIcon}
            >
            <Box width={isDemo ? "40%" : "20%"}>
                {props.action.items.map((item: string, index: number) => <Chip key={`${props.action_id}-item${index}`} style={{ maxWidth: "100px" }} label={startCase(item)}/>)}
            </Box>
            <Box width="20%" textAlign="center">
                <Typography variant='body2'>Est. Costs</Typography>
                <Typography variant='body2' fontWeight={600}>
                    {props.action.totalCostGbp?.toLocaleString('en-GB', {maximumSignificantDigits: 2, currency: 'GBP', style: "currency"})}
                </Typography>
            </Box>
            <Box width="20%" textAlign="center">
                <Typography variant='body2'>Energy cost savings p.a.</Typography>
                <Typography variant='body2' fontWeight={600}>
                    {(energyCostSavingsPercent).toLocaleString('en-GB', {style: "percent"})} ({energyCostSavingsGbp.toLocaleString('en-GB', {maximumSignificantDigits: 2, currency: 'GBP', style: "currency"})})
                </Typography>
            </Box>
            {!isDemo && <Box width="20%" textAlign="center">
                <Typography variant='body2'>ROI</Typography>
                <Typography variant='body2' fontWeight={600}>
                    {(derisk.fraction).toLocaleString('en-GB', {style: "percent"})} ({derisk.value.toLocaleString('en-GB', {maximumSignificantDigits: 2, currency: 'GBP', style: "currency"})})
                </Typography>
            </Box>}
            <Box width="20%" textAlign="center">
                <Typography variant='body2'>CO₂ avoided</Typography>
                <Typography variant='body2' fontWeight={600}>
                    {((props.asset.stats.co2G - props.action.stats.co2G) / props.asset.stats.co2G).toLocaleString('en-GB', {style: "percent", maximumFractionDigits: 0})}
                </Typography>
            </Box>
        </AccordionSummary>
        <AccordionDetails
            id={`accordion_details_${itemId}`}
            sx={{backgroundColor: theme.palette.grey[100]}}>
            {!isActiveAction && <ActionDetails {...props} />}
            {isActiveAction && <VerificationProgressIndicator 
                verificationObjective={props.action.verificationObjective as VerificationObjectiveModel} 
                propertyStats={props.asset.stats} />}
        </AccordionDetails>
    </Accordion>)
}

export default ActionListItem;