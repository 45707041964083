import { EpcGroupBounds } from "../components/key-performance-indicator"

export const EpcEerGroups: EpcGroupBounds = {
    'A': {lower: 92},
    'B': {upper: 92, lower: 81},
    'C': {upper: 81, lower: 69},
    'D': {upper: 69, lower: 55},
    'E': {upper: 55, lower: 39},
    'F': {upper: 39, lower: 21},
    'G': {upper: 21},
}

export function energyCostToEerSap(cost: number, total_floor_area: number) {
    const DEF = 0.42 // Energy cost deflator
    const ECF = DEF * cost / (total_floor_area + 45)
    if (ECF >= 3.5)
        return 117 - 121 * Math.log10(ECF)
    else
        return 100 - 13.95 * ECF
}


export function energyCostToEirSap(kgCO2: number, total_floor_area: number) {
    const CF = kgCO2 / (total_floor_area + 45)
    if (CF >= 28.3)
        return 200 - 95 * Math.log10(CF)
    else
        return 100 - 1.34 * CF
}

export const epcRatingColors = {
    a: "#0b9446",
    b: "#2ab773",
    c: "#90c53f",
    d: "#f6ee31",
    e: "#fcb040",
    f: "#f7941d",
    g: "#ee1b22",
}