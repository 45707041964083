import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import { useQuery } from "react-query";
import { SettingsService } from "../api/cl-client";

export interface IAppContext {
    settings: { [name: string]: string };
    isProduction: boolean;
    setAcceptCookies?: (acceptCookies: boolean) => void;
    acceptCookies: boolean;
}

const appContext = createContext<IAppContext>({
    settings: {},
    isProduction: true,
    acceptCookies: false,
});

export function ProvideAppContext({ children }: PropsWithChildren<{}>) {
    const context = useProvideAppContext();

    const handleAcceptCookie = useCallback(
        () => {
            context.setAcceptCookies && context.setAcceptCookies(true);
        },
        [context],
    )

    useEffect(() => {
        const isConsent = getCookieConsentValue();
        if (isConsent === "true") {
          handleAcceptCookie();
        }
    }, [handleAcceptCookie]);


    if (Object.keys(context.settings).length === 0) {
        console.log("Loading settings...");
        return <p>Loading app settings</p>
    } else {
        return (
            <appContext.Provider value={context}>
                <CookieConsent
                    enableDeclineButton
                    onAccept={handleAcceptCookie}
                    //onDecline={handleDeclineCookie}
                >
                    This website uses cookies to enhance the user experience.
                </CookieConsent>
                {context.setAcceptCookies && children}
            </appContext.Provider>
        );
    }
}

export function useAppContext() {
    return useContext<IAppContext>(appContext);
}

export function useProvideAppContext(): IAppContext {
    const [settings, setSettings] = useState<{[name: string]: string}>({});
    const [acceptCookies, setAcceptCookies] = useState(false);

    useQuery('settings', SettingsService.readSettingsApiSettingsGet,
    {
        onSuccess: (data) => {
            setSettings(data);
        }
    })

    return {
        settings,
        isProduction: process.env['NODE_ENV'] === 'production' && window.location.host.endsWith('carbonlaces.com'),
        acceptCookies,
        setAcceptCookies
    };
}
