import { FC, useMemo } from 'react';


// import mapboxgl from "worker-loader!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import { useAppContext } from './app-context';

//import 'mapbox-gl/dist/mapbox-gl.css';
 
// console.log(process.env)
//mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY as string;

interface IPropertyMapProps {
    lng?: number;
    lat?: number;
    zoom?: number;
}
const containerStyle = {
    width: '100%',
    height: '300px'
};

const PropertyMap: FC<IPropertyMapProps> = (props) => {
    // console.log('PropertyMap', props)
    const appContext = useAppContext();

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: appContext.settings['REACT_APP_GOOGLE_MAPS_API_KEY'] as string // ,
        // ...otherOptions
    })

    const center: google.maps.LatLngLiteral = useMemo(() => ({ 
        lng: props.lng ? props.lng : 51.5072, 
        lat: props.lat ? props.lat : 0.1276 }), [props.lng, props.lat]);

    const renderMap = () => {

        return <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          mapTypeId="sattelite"
          zoom={props.zoom ? props.zoom : 15}
          options={{
            disableDefaultUI: true,
            draggable: false,
            keyboardShortcuts: false,
            styles: [
                {
                    featureType: "poi.business",
                    stylers: [{ visibility: "off" }],
                }
            ]
          }}
        >
          <Marker position={center} visible={true} />
        </GoogleMap>
    }

    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>
    }
    
    return isLoaded ? renderMap() : <div>Loading...</div>
        
    

    // mapbox code import issues
    //const mapContainer = useRef<any>(null);
    //const map = useRef<mapboxgl.Map | null>(null);
//
    //
    //useEffect(() => {
    //    const center : mapboxgl.LngLatLike  = [
    //        props.lng ? props.lng : 51.5072, 
    //        props.lat ? props.lat : 0.1276
    //    ];
//
    //    if (!map.current) {
    //         // initialize map only once
    //        map.current = new mapboxgl.Map({
    //            container: mapContainer.current,
    //            style: 'mapbox://styles/mapbox/streets-v11',
    //            center,
    //            zoom: props.zoom ? props.zoom : 9
    //        });
    //    } else {
    //        map.current.setCenter(center);
    //        map.current.setZoom(props.zoom ? props.zoom : 9);
    //    }
    //}, [props.lat, props.lng, props.zoom]);
//
    //return (
    //    <div ref={mapContainer} className="map-container" />
    //);
}

export default PropertyMap