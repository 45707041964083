/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum LandTypeEnum {
    SETTLEMENT = 'settlement',
    GRASSLAND = 'grassland',
    FOREST = 'forest',
    CROPLAND = 'cropland',
    WETLANDS = 'wetlands',
    OTHER_LAND = 'other_land',
}
