/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum DeviceTypeEnum {
    SMART_METER = 'smart_meter',
}
