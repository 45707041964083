import { TextField, Grid, Button, Table, TableBody, TableHead, TableRow, TableCell, Stack } from '@mui/material';
import { FC, useMemo, useState } from 'react';
import { AssetsService, VehicleEnquiryService, VehicleModel } from '../api/cl-client';
import { useMutation, useQuery } from 'react-query';
import { getKeys } from '../utils/typescript';

interface AssetFormVehicleProps {
    asset?: VehicleModel;
    onAssetChange?: (asset: VehicleModel) => void;
}

const AssetFormVehicle: FC<AssetFormVehicleProps> = () => {
    const [ registrationNumber, setRegistrationNumber ] = useState<string>();

    const { data, refetch, isLoading, isSuccess } = useQuery(["vehicleRegistration", registrationNumber], () => VehicleEnquiryService.readVehicleEnquiryApiVehicleEnquiryRegistrationNumberGet(registrationNumber as string), { refetchOnWindowFocus: false, enabled: false });
    const { mutate, isLoading: isLoadingMutation, isSuccess: isSuccessMutation } = useMutation(AssetsService.postAssetApiAssetsPost);


    const registrationData = useMemo(() => {
        if (data) {
            return getKeys<VehicleModel>(data).map((key) => (
                <TableRow>
                    <TableCell>{key}</TableCell>
                    <TableCell>{data[key]}</TableCell>
                </TableRow>
            ))
        }
    }, [data]);
    
    const dataForm = useMemo(() => {
        if (data) {
            const onSubmit = () => {
                if (data) {
                    const vehicle: VehicleModel = { ...data, assetType: VehicleModel.assetType.VEHICLE };
                    mutate(vehicle);
                }
            }

            return (<>
                <Grid item xs={12}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Property</TableCell>
                                <TableCell>Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {registrationData}
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary" disabled={isLoadingMutation || isSuccessMutation} onClick={onSubmit}>Submit</Button>
                </Grid>
            </>)
        }
    }, [data, registrationData, mutate, isLoadingMutation, isSuccessMutation]); 

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={1}>
                        <TextField label="Registration number" variant="standard" onChange={(e) => setRegistrationNumber(e.target.value)}/>
                        <Button type="submit" variant="outlined" color="primary" disabled={isLoading || isSuccess} onClick={() => refetch()}>Query</Button>
                    </Stack>
                </Grid>
                {dataForm}
            </Grid>
        </>
    );
}

export default AssetFormVehicle;