/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum MotStatusEnum {
    NO_DETAILS_HELD_BY_DVLA = 'No details held by DVLA',
    NO_RESULTS_RETURNED = 'No results returned',
    NOT_VALID = 'Not valid',
    VALID = 'Valid',
}
