import { EpcGroupBounds } from "../components/key-performance-indicator"

export const thermalPerformanceGroups: EpcGroupBounds = {
    'A': {lower: 0,  upper: 5},
    'B': {lower: 5,  upper: 15},
    'C': {lower: 15, upper: 33},
    'D': {lower: 33, upper: 54},
    'E': {lower: 54, upper: 82},
    'F': {lower: 82, upper: 125},
    'G': {lower: 125},
}
