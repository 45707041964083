/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum TaxStatusEnum {
    NOT_TAXED_FOR_ON_ROAD_USE = 'Not Taxed for on Road Use',
    SORN = 'SORN',
    TAXED = 'Taxed',
    UNTAXED = 'Untaxed',
}
