import { FC, useMemo, useState } from 'react';

import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import DeleteIcon from '@mui/icons-material/Delete';

import { PropertyModel, AssetsService, LandModel, VehicleModel, AssetTypeEnum } from '../../api/cl-client';

import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { useTheme } from '@mui/material';
import { getMaxDeRisk } from '../../utils/asset';
import { useUser } from '../../components/user-context';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const Assets: FC = () => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const queryClient = useQueryClient();

    const user = useUser()

    const isNatWest = useMemo(() => (
        user.clientPrincipal?.userId === '79cd03b9-d419-431d-ad28-911a27282b5d'
    ), [user])

    const mutateDownloadAssets = useMutation(
        AssetsService.getAssetsCsvApiAssetsCsvGet,
        { onSuccess: (data) => {
            // save data to file
            if (data) {
                const blob = new Blob([data], { type: 'text/csv' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                const dateTimeString = new Date().toISOString().replace(/:/g, '-');
                a.download = `carbonlaces-export-${dateTimeString}.csv`;
                a.click();
                window.URL.revokeObjectURL(url);
            }
        }})
    
    const mutateDeleteAssets = useMutation(
        AssetsService.deleteAssetsApiAssetsDelete,
        { onSuccess: () => {
            queryClient.invalidateQueries(["assets"])
        }})
    
    const mutateDeleteAsset = useMutation(
        AssetsService.deleteAssetApiAssetsAssetIdDelete,
        { onSuccess: () => {
            queryClient.invalidateQueries(["assets"])
        }})

    const theme = useTheme()

    const navigate = useNavigate()

    if (user.assets?.isLoading || user.assets?.isFetching) return <Typography variant='h4'>Loading...</Typography>;

    if (user.assets?.error) return <Typography variant='h4'>{"An error has occurred: " + user.assets.error}</Typography>;

    const tempRender = (temp: number | undefined) =>  <Typography variant='h6' color={temp === undefined ? theme.palette.grey[500] : temp <= 1.5 ? theme.palette.success.light : temp <= 2.5 ? theme.palette.warning.light : theme.palette.error.light }>{temp === undefined ? '--' : temp.toFixed(1)}</Typography>;

    function deleteAsset(id: string) {
        mutateDeleteAsset.mutate(id);
    }

    const getValueDeRisk = (row: PropertyModel | VehicleModel | LandModel) => {
        if (row.assetType === AssetTypeEnum.PROPERTY && row.packages && row.packages.length > 0) {
            return getMaxDeRisk(row)?.fraction
        }
    }

    const renderDeRisk = (row: PropertyModel | VehicleModel | LandModel) => {
        const deRiskValue = getValueDeRisk(row)
        if (deRiskValue) {
            return <Typography variant='body1'>{deRiskValue.toLocaleString('en-GB', {style: "percent"})}</Typography>
        } else {
            return <Typography variant='body1'>-</Typography>
        }  
    }

    const renderOverview = (row: PropertyModel | VehicleModel | LandModel) => {
        if (row.assetType === AssetTypeEnum.PROPERTY) {
            return (<Grid item xs={12}>
                        <Typography variant='body1'>{row.address.address1}, {row.address.city}, {row.address.postcode} </Typography>
                    </Grid>)
        } else if (row.assetType === VehicleModel.assetType.VEHICLE) {
            return (<Grid item xs={12}>
                        <Typography variant='body1'>Registration: {row.registrationNumber}, Make: {row.make}, Emissions: {row.co2Emissions} g/km </Typography>
                    </Grid>)
        }
    }

    const columns: GridColDef<(PropertyModel | LandModel | VehicleModel)>[] = [
        { field: 'assetType', headerName: 'Type', valueGetter: ({row}) => row.assetType },
        { field: 'virtualEpc', headerName: 'CLEVR™', valueGetter: ({row}) => row.stats?.virtualEpcEerClass, renderCell: ({row}) => <Typography variant='body1'>{row.stats?.virtualEpcEerClass}</Typography> },
        { field: 'temperature', headerName: 'Temp. Alignment', valueGetter: ({row}) => row.stats?.temperatureAlignment, renderCell: ({row}) => tempRender(row.stats?.temperatureAlignment) },
        { 
            field: 'deRisk', 
            headerName: 'ROI', 
            valueGetter: ({row}) => getValueDeRisk(row), 
            renderCell: ({row}) => renderDeRisk(row),
        },
        { field: '', headerName: 'Overview', flex: 1, renderCell: ({row}) => renderOverview(row)},
        { field: 'assetTags', headerName: 'Tags', valueGetter: ({row}) => row.assetTags },
        { field: 'actions', headerName: 'Actions', renderCell: ({row}) => {
            if (row._id !== undefined) {
                return (
                    <ButtonGroup>
                        <IconButton onClick={(e) => { e.stopPropagation(); deleteAsset(row._id as string)}} color="warning"><DeleteIcon /></IconButton>
                    </ButtonGroup>
                )
            }
        }},
    ];

    if (isNatWest) {
        columns.splice(3,1)
    }

    return ( 
    <>
        <Typography variant='h4'>Assets</Typography>
        <DataGrid sx={{ minHeight: '500px' }} getRowId={(row) => row._id as string} rows={user.assets?.data ? user.assets.data : []} columns={columns} onRowClick={(params) => {navigate('./' + params.row._id)}} />
        <ButtonGroup>
            <Button variant='contained' component={RouterLink} to="./post">Add Asset</Button>
            <Button variant='contained' color='info' onClick={() => mutateDownloadAssets.mutate()}>Download Assets</Button>
            <Button variant='contained' color='error' onClick={() => setShowDeleteModal(true)}>Delete All</Button>
        </ButtonGroup>
        <Modal
            open={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Delete all assets?
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    This will delete all assets. Are you sure?
                </Typography>
                <ButtonGroup>
                    <Button variant='contained' onClick={() => setShowDeleteModal(false)}>Cancel</Button>
                    <Button variant='contained' color='error' onClick={() => { mutateDeleteAssets.mutate(); setShowDeleteModal(false)}}>Delete</Button>
                </ButtonGroup>
            </Box>
        </Modal>
    </> );
}

export default Assets;
