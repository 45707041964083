import { useState, FC, useEffect } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DashboardNavbar } from './dashboard-navbar';
import { DashboardSidebar } from './dashboard-sidebar';
import { useUser } from './user-context';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
}));

interface DashboardLayoutProps {
    children: React.ReactNode;
}

export const DashboardLayout: FC<DashboardLayoutProps> = (props) => {
  const { children } = props;
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const auth = useUser();

  useEffect(() => {
    // console.log(`isAuthenticated: ${auth.isAuthenticated}`);
    if (auth.isAuthenticated) {
      setSidebarOpen(true);
    }
  }, [auth.isAuthenticated]);

  useEffect(() => {
    // console.log(`isSidebarOpen: ${sidebarOpen}`);
  }, [sidebarOpen]);
  

  return (
    <>
      <DashboardLayoutRoot
        sx={{
            marginLeft: {
                xs: "0px",
                lg: auth.isAuthenticated ? "280px" : "0px"
              },
            minHeight: "100vh"
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%',
            padding: '64px 0 0 0'
          }}
        >
          {children}
        </Box>
      </DashboardLayoutRoot>
      <DashboardNavbar onSidebarOpen={() => setSidebarOpen(true)} />
      { auth.isAuthenticated ? <DashboardSidebar
        onClose={() => setSidebarOpen(false)}
        open={sidebarOpen}
      /> : undefined }
    </>
  );
}
