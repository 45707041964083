import { useState, FC, useEffect, useRef } from "react";

import TextField from "@mui/material/TextField";

import { Client, lookupAddress, UkAddress} from "@ideal-postcodes/core-axios";
import { Autocomplete, debounce, Grid } from "@mui/material";
import { useAppContext } from "./app-context";


function getTestAddress(id: number): UkAddress 
{
    return {
        line_1: "test property " + id,
        post_town: "READING",
        latitude: 51.446626,
        longitude: -0.9705181,
        postcode: "RG1 2PU",
        uprn: id.toString(),
        country: "England",
        postcode_outward: "",
        postcode_inward: "",
        dependant_locality: "",
        double_dependant_locality: "",
        thoroughfare: "",
        dependant_thoroughfare: "",
        building_number: "",
        building_name: "",
        sub_building_name: "",
        po_box: "",
        department_name: "",
        organisation_name: "",
        udprn: 0,
        umprn: 0,
        postcode_type: "S",
        su_organisation_indicator: "",
        delivery_point_suffix: "",
        line_2: "",
        line_3: "",
        premise: "",
        county: "",
        administrative_county: "",
        postal_county: "",
        traditional_county: "",
        district: "",
        ward: "",
        eastings: 0,
        northings: 0,
        id: "",
        dataset: "paf",
        country_iso: "GBR"
    }
}

interface IAddressLookupProps {
    id: number;
    onAddressChanged: (address: UkAddress | null, id: number) => void;
    address?: string;
    selectFirstBest?: boolean;
}

const AddressLookup: FC<IAddressLookupProps> = (props) => {

    const [addressList, setAddressList] = useState<UkAddress[]>([]);

    const [value, setValue] = useState<UkAddress | null>(null);

    const appContext = useAppContext();

    const [searchQuery, setSearchQuery] = useState<string>(props.address ? props.address : "");

    const lastSearchQuery = useRef<string | undefined>();

    const getOptionLabel = (option: UkAddress) => `${option.premise} ${option.thoroughfare}, ${option.postcode}, ${option.post_town}`

    useEffect(() => {
        const eventHandler = async () => {
            // don't search if the query is the same as the last one
            if (searchQuery.length > 0 && searchQuery !== lastSearchQuery.current) {
                // check that query is not equals to selected item
                if (!value || getOptionLabel(value) !== searchQuery) {
                    let lookup: UkAddress[] = []
                    if (searchQuery.trim().toLowerCase() === 'test') {
                        const id = Math.round(Math.random() * 900000000)
                        lookup = [getTestAddress(id)]
                    } else {
                        const client = new Client({ api_key: appContext.settings['REACT_APP_POSTCODE_LOOKUP_API_KEY'] as string});
                        // const client = new Client({ api_key: "iddqd" });
                        lookup = await lookupAddress({client, query: searchQuery});
                    }
                    setAddressList(lookup);
                    lastSearchQuery.current = searchQuery;
                    // console.log("Updated address list", lookup);

                    if (props.selectFirstBest) {
                        setValue(lookup[0]);
                        props.onAddressChanged(lookup[0], props.id);
                    }
                }
            }
        };

        const debounced = debounce(eventHandler, 1000);
        debounced();

        return () => {
            debounced.clear();
        }
    }, [searchQuery, value, appContext.settings, props]);

    const onInputChange = (event: unknown, value: string) => {
        setSearchQuery(value.trim());
    };

    return ( <>
        <Grid sx={{display: 'flex'}}>
            <Autocomplete 
                autoHighlight={true}
                value={value}
                options={addressList} 
                getOptionLabel={getOptionLabel} 
                filterOptions={(options) => options}
                renderInput={(params) => <TextField {...params} label="Address" variant="standard" />} 
                onChange={(event, value) => { 
                    setValue(value); 
                    props.onAddressChanged(value, props.id);}}
                onInputChange={onInputChange} 
                isOptionEqualToValue={(option, value) => option.uprn === value.uprn}
                size="small" 
                sx={{flex: 1}} />
            {//<Button onClick={eventHandler}>Lookup</Button>
            }
        </Grid>
    </> );
}

export default AddressLookup;