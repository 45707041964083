import { FC, useMemo } from 'react';
import { Link } from "react-router-dom";
import { PathMatch } from 'react-router-dom';
import { Box, Divider, Drawer, useMediaQuery, Theme, Typography } from '@mui/material';
import { Logo } from './logo';
import { NavItem, NavItemProps } from './nav-item';

import generatedGitInfo from '../generated-git-info.json';
import { useUser } from './user-context';

const regex_uuid1 = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;

interface DashboardSidebarProps {
    open: boolean;
    onClose: () => void;
}

export const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {

  const user = useUser()

  const isNatWest = useMemo(() => (
      user.clientPrincipal?.userId === '79cd03b9-d419-431d-ad28-911a27282b5d'
  ), [user])

  const { open, onClose } = props;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'), {
    defaultMatches: true,
    noSsr: false
  });

  const items: NavItemProps[] = useMemo(() => ([
    {
      to: '/',
      title: 'Dashboard',
      isVisible: () => !isNatWest
    },
    {
      to: '/assets',
      title: 'Asset List',
      children: [
        {
          to: '/assets/post',
          title: 'New asset'
        },
        {
          to: '/assets/:assetId',
          title: 'View asset',
          isVisible: (match: PathMatch<string>) => match.params.assetId ? regex_uuid1.test(match.params.assetId) : false,
          isActive: (match: PathMatch<string>) => match.params.assetId ? regex_uuid1.test(match.params.assetId) : false
        }
      ]
    }
  ]), [isNatWest]);

  const content = (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <div>
          <Box sx={{ p: 3 }}>
            <Link to="/" >
                <Logo
                    variant='light'
                  sx={{
                    height: 42,
                    width: 42
                  }}
                />
            </Link>
          </Box>
        </div>
        <Box sx={{ flexGrow: 1 }}>
          {items.map((item) => (
            <NavItem key={item.title} {...item} />
          ))}
        </Box>
        <Divider sx={{ borderColor: 'textSecondary' }} />
        <Box sx={{ p: 1 }}>
            <Typography variant='body2' color='textSecondary'>© CarbonLaces {new Date().getFullYear()} | v{process.env.REACT_APP_VERSION}@{generatedGitInfo.gitCommitHash}</Typography>
        </Box>
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        PaperProps={{
          sx: {
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          width: 280
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
      color='primary'
    >
      {content}
    </Drawer>
  );
};
