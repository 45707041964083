import { FC, useMemo, useState } from 'react';

import Typography from '@mui/material/Typography';
import { Link, useParams } from 'react-router-dom';
import { AssetTypeEnum } from '../../api/cl-client';

import { InputLabel, MenuItem, Select, FormControl, Stack, Button } from '@mui/material';
import AssetFormProperty from '../../components/asset-form-property';
import AssetFormVehicle from '../../components/asset-form-vehicle';
import AssetFormLand from '../../components/asset-form-land';

interface EditAssetProps {
}

const EditAsset: FC<EditAssetProps> = () => {
    const params = useParams();
    const [assetType, setAssetType] = useState<AssetTypeEnum>();

    const assetTypesMenuItems = useMemo(() => Object.values(AssetTypeEnum).map((key) => <MenuItem key={key} value={key} >{key}</MenuItem>), [])

    return (
        <>
            <Stack flexDirection='row'>
                <Typography variant='h4' color='primary' flex={1}>{params.portfolioId ? 'Edit' : 'Create'} asset</Typography>
                <Button variant="contained" component={Link} to='./../postCsv'>Upload from CSV</Button>
            </Stack>
            
            <FormControl fullWidth>
                <InputLabel id="asset-type-select-label">Asset type</InputLabel>
                <Select variant="standard" value={assetType} onChange={(e) => setAssetType(e.target.value as AssetTypeEnum)} label="Asset type" labelId="asset-type-select-label" >
                    {assetTypesMenuItems}
                </Select>
            </FormControl>
            {assetType === AssetTypeEnum.PROPERTY && <AssetFormProperty />}
            {assetType === AssetTypeEnum.VEHICLE && <AssetFormVehicle />}
            {assetType === AssetTypeEnum.LAND && <AssetFormLand />}
        </>
    );
}

export default EditAsset;