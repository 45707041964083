/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum AssetTypeEnum {
    PROPERTY = 'property',
    VEHICLE = 'vehicle',
    LAND = 'land',
}
