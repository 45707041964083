import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { ProvideUser } from './components/user-context';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SnackbarProvider } from 'notistack';
import './index.scss';
import App from './App';
import store from './app/store';
import reportWebVitals from './reportWebVitals';
import theme from './theme';
import { ProvideAppContext } from './components/app-context';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-NHLH2PQ'
}

TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Create a client
const queryClient = new QueryClient({defaultOptions: {queries: {refetchOnWindowFocus: false}}})

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <SnackbarProvider 
              maxSnack={3}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}>
              <ProvideAppContext>
                <ProvideUser>
                  <BrowserRouter>
                      <App />
                  </BrowserRouter>
                </ProvideUser>
              </ProvideAppContext>
            </SnackbarProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
