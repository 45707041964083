import { Tooltip, Grid, Paper, Typography, Stack, TableContainer, Table, TableBody, TableRow, TableCell, List, Button, ListItem, useTheme, Box, Dialog, DialogTitle, DialogContent, FormControlLabel, Checkbox, DialogActions, CircularProgress } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import KeyPerformanceIndicator from "./key-performance-indicator";
import PropertyMap from "./property-map";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { PropertyModel } from "../api/cl-client";
import SimpleActionListItem from "./simple-action-list-item";
import ActionListItem from "./action-item";
import { ghgTargetC15KgCo2eSqmYr, ghgTargetC20KgCo2eSqmYr } from '../utils/crremTargets';
import { getMaxDeRisk } from "../utils/asset";
import KeyPerformanceIndicatorGroup, { IKeyPerformanceIndicatorExtended } from "./key-performance-indicator/group";
import { useUser } from "./user-context";
import { thermalPerformanceGroups } from "../utils/thermalPerformance";
import { EpcEerGroups } from "../utils/epc";

interface AssetViewPropertyProps {
    asset: PropertyModel
}

function getTrend(delta: number | undefined) {
    if (delta === undefined) {
        return undefined
    } else if (Math.abs(delta) < 0.01) {
        return "flat"
    } else if (delta < 0) {
        return "down"
    } else {
        return "up"
    }
}

const Property: FC<AssetViewPropertyProps> = (props) => {
    const navigate = useNavigate()
    const [consentDialogOpen, setConsentDialogOpen] = useState(false)

    useEffect(() => {
        if (!props.asset.stats) {
            navigate('/assets')
        }
    }, [navigate, props.asset.stats])

    const user = useUser();

    const isNatWest = useMemo(() => (
        user.clientPrincipal?.userId === '79cd03b9-d419-431d-ad28-911a27282b5d'
    ), [user])

    const theme = useTheme();

    const deviceList = ['Electricity smart meter', 'Gas smart meter', 'Heat pump'].map((name, index) => <ListItem key={index}>{name}</ListItem>);

    const kpis = useMemo(() => {
        const kpis: IKeyPerformanceIndicatorExtended[] = []

        // console.log('Generate KPIs')
        if (!isNatWest) {
            kpis.push({
                name: "Energy Intensity",
                unit: "kWh/m2/yr",
                value: props.asset.stats?.consumptionKwh / props.asset.config.totalFloorArea,
                position: 'top',
                trend: getTrend(props.asset.stats?.deltaConsumptionKwh),
                plots: [
                    {
                        data: props.asset.stats['monthly'].map((monthStats: any) => ({
                            y: monthStats['consumption_kwh'] / props.asset.config['totalFloorArea'],
                            x: `${monthStats['year']}/${monthStats['month']}`
                        })),
                        xLabel: 'Time',
                        yLabel: 'Energy Intensity (kWh/m2/yr)'},
                    {
                        ...user.portfolio?.data?.energyIntensityHistogram,
                        xLabel: 'Time',
                        yLabel: 'Energy Intensity (kWh/m2/yr)'}
                ]
            })

            kpis.push({
                name: "Carbon Intensity",
                unit: "kgCO2e/m2/yr",
                value: props.asset.stats['co2G'] / props.asset.config['totalFloorArea'] / 1000,
                position: 'top',
                trend: getTrend(props.asset.stats?.deltaCo2G),
                plots: [
                    {
                        data: props.asset.stats['monthly'].map((monthStats: any) => ({
                            y: monthStats['co2_g'] / props.asset.config['totalFloorArea'] / 1000,
                            x: `${monthStats['year']}/${monthStats['month']}`
                        })),
                        xLabel: 'Time',
                        yLabel: 'Carbon Intensity (kgCO2e/m2/yr)'},
                    {
                        ...user.portfolio?.data?.carbonIntensityHistogram,
                        buckets: user.portfolio?.data?.carbonIntensityHistogram.buckets.map((value: number) => value/1000),
                        xLabel: 'Time',
                        yLabel: 'Carbon Intensity (kgCO2e/m2/yr)'}
                ]
            })

            kpis.push({
                name: "Cost Intensity",
                unit: "£/m2/yr",
                value: props.asset.stats['energyCostGbp'] / props.asset.config['totalFloorArea'],
                position: 'top',
                trend: getTrend(props.asset.stats?.deltaEnergyCostGbp),
                plots: [
                    {
                        data: props.asset.stats['monthly'].map((monthStats: any) => ({
                            y: monthStats['energy_cost_gbp'] / props.asset.config['totalFloorArea'],
                            x: `${monthStats['year']}/${monthStats['month']}`
                        })),
                        xLabel: 'Time',
                        yLabel: 'Cost Intensity (£/m2/yr)'},
                    {
                        ...user.portfolio?.data?.costIntensityHistogram,
                        xLabel: 'Time',
                        yLabel: 'Cost Intensity (£/m2/yr)'}
                ]
            })
        }

        kpis.push({
            name: "Energy",
            unit: "kWh/yr",
            value: props.asset.stats?.consumptionKwh,
            position: 'top',
            trend: getTrend(props.asset.stats?.deltaConsumptionKwh),
            plots: [
                {
                    data: props.asset.stats['monthly'].map((monthStats: any) => ({
                        y: monthStats['consumption_kwh'],
                        x: `${monthStats['year']}/${monthStats['month']}`
                    })),
                    xLabel: 'Time',
                    yLabel: 'Energy (kWh/yr)'},
                {
                    ...user.portfolio?.data?.energyIntensityHistogram,
                    buckets: user.portfolio?.data?.energyIntensityHistogram.buckets.map((value: number) => value * props.asset.config['totalFloorArea'] ),
                    xLabel: 'Time',
                    yLabel: 'Energy (kWh/yr)'}
            ]
        })

        kpis.push({
            name: "Carbon",
            unit: "kgCO2e/yr",
            value: props.asset.stats['co2G'] / 1000,
            position: 'top',
            trend: getTrend(props.asset.stats?.deltaCo2G),
            plots: [
                {
                    data: props.asset.stats['monthly'].map((monthStats: any) => ({
                        y: monthStats['co2_g'] / 1000,
                        x: `${monthStats['year']}/${monthStats['month']}`
                    })),
                    xLabel: 'Time',
                    yLabel: 'Carbon (kgCO2e/yr)'},
                {
                    ...user.portfolio?.data?.carbonIntensityHistogram,
                    buckets: user.portfolio?.data?.carbonIntensityHistogram.buckets.map((value: number) => value * props.asset.config['totalFloorArea'] /1000),
                    xLabel: 'Time',
                    yLabel: 'Carbon (kgCO2e/yr)'}
            ]
        })

        kpis.push({
            name: "Cost",
            unit: "£/yr",
            value: props.asset.stats['energyCostGbp'],
            position: 'top',
            trend: getTrend(props.asset.stats?.deltaEnergyCostGbp),
            plots: [
                {
                    data: props.asset.stats['monthly'].map((monthStats: any) => ({
                        y: monthStats['energy_cost_gbp'],
                        x: `${monthStats['year']}/${monthStats['month']}`
                    })),
                    xLabel: 'Time',
                    yLabel: 'Cost (£/yr)'},
                {
                    ...user.portfolio?.data?.costIntensityHistogram,
                    buckets: user.portfolio?.data?.costIntensityHistogram.buckets.map((value: number) => value * props.asset.config['totalFloorArea']),
                    xLabel: 'Time',
                    yLabel: 'Cost (£/yr)'}
            ]
        })

        if (props.asset.counterFactual && props.asset.counterFactual.monthlySavings) {
            kpis.push({
                name: "Energy Reduction",
                unit: "KWh",
                value: props.asset.counterFactual?.totalSavings,
                position: 'top',
                plots: [
                    {
                        data: props.asset.counterFactual.monthlySavings.map((saving: any) => ({
                            y: saving['meteredSavings'] as number,
                            x: `${saving['year']}/${saving['month']}`
                        })),
                        xLabel: 'Time',
                        yLabel: 'Metered Savings (KWh)'}
                ]
            })
        }

        !isNatWest && kpis.push({
            name: "Energy Cost Savings",
            unit: "£",
            value: 0,
            position: 'bottom',
            disabled: true
        })

        !isNatWest && kpis.push({
            name: "Grants",
            unit: "£",
            value: 0,
            position: 'bottom',
            disabled: true
        })

        kpis.push({
            name: "Flex Demand Income",
            unit: "£",
            value: 0,
            position: 'bottom',
            disabled: true
        })

        !isNatWest && kpis.push({
            name: "Est. Carbon Cert. Returns",
            unit: "£",
            value: 0,
            position: 'bottom',
            disabled: true
        })

        const crrem15Year = props.asset.crremStats?.stats?.year.reduce((acc: number, year: number, index: number) => (
            props.asset.crremStats?.stats?.ghgActualKgCo2eSqmYr[index] < ghgTargetC15KgCo2eSqmYr[year] ? year : acc
        ), new Date().getFullYear())
        const crrem15NYears = crrem15Year - new Date().getFullYear()
        !isNatWest && kpis.push({
            name: "CRREM 1.5°C compliant until",
            value: `${crrem15Year} (${crrem15NYears} ${Math.abs(crrem15NYears) === 1 ? 'Year' : 'Years'})`,
            position: 'bottom'
        })
        
        const crrem20Year = props.asset.crremStats?.stats?.year.reduce((acc: number, year: number, index: number) => (
            props.asset.crremStats?.stats?.ghgActualKgCo2eSqmYr[index] < ghgTargetC20KgCo2eSqmYr[year] ? year : acc
            ), new Date().getFullYear())
        const crrem20NYears = crrem20Year - new Date().getFullYear()
        !isNatWest && kpis.push({
            name: "CRREM 2.0°C compliant until",
            value: `${crrem20Year} (${crrem20NYears} ${Math.abs(crrem20NYears) === 1 ? 'Year' : 'Years'})`,
            position: 'bottom'
        })

        // console.log(kpis)
        return kpis
    }, [props.asset, user.portfolio, isNatWest])

    const actionItems = useMemo(() => {
        // console.log(props.asset);
        if (!isNatWest) {
            return props.asset.packages?.map((pkg: any, index: number) => (
                <ActionListItem 
                    asset={props.asset} 
                    action={pkg} 
                    action_id={index}
                    key={`action${index}`}/>
            ))
        } else {
            return <Typography>Coming soon</Typography>
        }
    }, [props.asset, isNatWest])

    const maxDeRisk = useMemo(() => getMaxDeRisk(props.asset), [props.asset])

    return (
    <>
        <Grid container spacing={3}>
            <Grid item xs={12} > 
                <Paper>
                    <Typography variant='h5'>{props.asset.address.address1}, {props.asset.address.city} {props.asset.address.postcode}</Typography>
                </Paper>
            </Grid>
            <Grid item xs={4} lg={3} >
                <Stack spacing={3}>
                    <Stack component={Paper}>
                        <PropertyMap lng={props.asset.address.longitude} lat={props.asset.address.latitude} zoom={17} />
                        <TableContainer >
                            <Table size="small">
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Address:</TableCell>
                                        <TableCell>{props.asset.address.address1}<br />{props.asset.address.address2}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>City:</TableCell>
                                        <TableCell>{props.asset.address.city}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Postcode:</TableCell>
                                        <TableCell>{props.asset.address.postcode}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Built form:</TableCell>
                                        <TableCell>{props.asset.config.propertyType ? props.asset.config.propertyType : 'N/A'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Year built:</TableCell>
                                        <TableCell>{props.asset.config.yearBuilt ? props.asset.config.yearBuilt : 'N/A'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2} sx={{textAlign: 'center', fontWeight: 600, backgroundColor: theme.palette.grey[100]}}>EPC</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Energy Efficiency:</TableCell>
                                        <TableCell>{props.asset.epc ? `${props.asset.epc.currentEnergyEfficiencyRating} (${props.asset.epc.currentEnergyEfficiency})` : 'N/A'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Environmental Impact:</TableCell>
                                        <TableCell>{props.asset.epc ? `${props.asset.epc.currentEnvironmentalImpactRating} (${props.asset.epc.currentEnvironmentalImpact})` : 'N/A'}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Stack>
                    <Stack component={Paper}>
                        <Typography variant='h6'>Installed devices</Typography>
                        <List>
                            {deviceList}
                        </List>
                        {!isNatWest && <Button component={RouterLink} to='./devices/post'>Link new device</Button>}
                        {isNatWest && <Button onClick={() => setConsentDialogOpen(true)}>Consent meter access</Button>}
                    </Stack>
                </Stack>
            </Grid>
            <Grid item xs={8} lg={9}>
                <Stack spacing={1} >
                    <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={1}> 
                        <Grid item xs={3} display="flex">
                            <Tooltip title="">
                                <KeyPerformanceIndicator name="CLEVR™" value={props.asset.stats?.virtualEpcEer} epc={EpcEerGroups} />
                            </Tooltip>
                        </Grid>
                        {props.asset.stats?.thermalPerformance && <Grid item xs={3} display="flex">
                            <Tooltip title="The Thermal Performance Indicator measures how much energy leaks from a property through the building fabric. A better rating score indicates better thermal efficiency, which can result in reduced energy consumption, lower bills, and a smaller carbon footprint.">
                                <KeyPerformanceIndicator name="Thermal Performance" value={props.asset.stats?.thermalPerformance} epc={thermalPerformanceGroups} unit="W/K" />
                            </Tooltip>
                        </Grid>}
                        <Grid item xs={3} display="flex">
                            <Tooltip title="The displayed temperature alignment score is an indication on how well the property aligns with the national 1.5°C paris targets.">
                                <KeyPerformanceIndicator name="Temperature" value={Math.round(props.asset.stats?.temperatureAlignment * 10) / 10} trend={getTrend(props.asset.stats?.deltaTemperatureAlignment)} unit='°C' />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={3} display="flex">
                            <Tooltip title="The risk score displayed is an aggregate of the Energy Use Risk, Exposure Risk and Financial Risk metrics of the Property.">
                                <KeyPerformanceIndicator name="Maximum ROI" value={!isNatWest ? maxDeRisk?.fraction.toLocaleString('en-GB', {style: "percent"}) : 'N/A'} />
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <KeyPerformanceIndicatorGroup kpis={kpis} itemsPerRow={4} itemsPerColumn={4} histogramAnnotationLabel="This Property" />
                    <Typography variant="h4" sx={{color: theme.palette.primary.main, paddingBottom: '0.5em'}}>Immediate Actions</Typography>
                    <Box>
                        <SimpleActionListItem 
                            assetId={props.asset._id as string}
                            name="Turn down the thermostat" 
                            description="18 degrees is enough for healthy adults, with slightly higher temperatures needed for the very old or young. So consider popping on a jumper at home and seeing what temperature your household is comfortable at."
                            unit="per °C"
                            energySavingsPercent={0.04} 
                            energySavingsGbp={props.asset.stats?.energyCostGbp * 0.04} 
                            co2AvoidedPercent={0.04}/>
                        <SimpleActionListItem 
                            assetId={props.asset._id as string}
                            name="Set boiler temperature to 55 °C" 
                            description="Your boiler’s flow setting controls the temperature water is when it leaves your boiler. Its default setting is usually too high. Turn it down a bit and your home will feel just as warm, but you could cut your energy bills."
                            energySavingsPercent={props.asset.stats?.energyCostGbp * 0.08 / props.asset.stats?.energyCostGbp} 
                            energySavingsGbp={props.asset.stats?.energyCostGbp * 0.08} 
                            co2AvoidedPercent={props.asset.stats?.co2G * 0.08 / props.asset.stats?.co2G}/>
                        <SimpleActionListItem 
                            assetId={props.asset._id as string}
                            name="Turn off the lights" 
                            description="Develop the habit of turning off the lights when your are leaving a room."
                            energySavingsPercent={0.03 * props.asset.stats?.gridElectricityKwh / props.asset.stats?.consumptionKwh} 
                            energySavingsGbp={0.03 * props.asset.stats?.gridElectricityCostGbp} 
                            co2AvoidedPercent={0.03 * props.asset.stats?.gridElectricityCo2G / props.asset.stats?.co2G}/>
                        <SimpleActionListItem 
                            assetId={props.asset._id as string}
                            name="Use LED lights" 
                            description="Lighting makes up around 11% of a typical power bill. LEDs use 70-80% less electricity."
                            unit="per bulb"
                            costGbp={3}
                            energySavingsPercent={9 / props.asset.stats?.energyCostGbp} 
                            energySavingsGbp={9} 
                            co2AvoidedPercent={props.asset.stats?.co2G * 9 / props.asset.stats?.energyCostGbp / props.asset.stats?.co2G}/>
                    </Box>
                    <Typography variant="h4" sx={{color: theme.palette.primary.main, paddingBottom: '0.5em'}}>Long-term Actions</Typography>
                    <Box>
                        {actionItems}
                        {props.asset.status !== 'done' ? <CircularProgress /> : null}
                    </Box>
                </Stack>
            </Grid>
        </Grid>
        <Dialog onClose={() => setConsentDialogOpen(false)} open={consentDialogOpen}>
            <DialogTitle>Consent meter access</DialogTitle>
            <DialogContent>
                <Typography>To acquire consent to read data from your electricity and gas smart meter we require a few details to establish that you indeed have the right to excess the data.</Typography>
                <FormControlLabel control={<Checkbox />} label="I consent access to the energy consumption data of this property" />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setConsentDialogOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    </>)
}

export default Property;
