import { Box, Tooltip, Grid, Paper, Typography, Stack, TableContainer, Table, TableBody, TableRow, TableCell, useTheme, Dialog, DialogContent, DialogTitle, CircularProgress } from "@mui/material";
import { FC, useMemo, useState } from "react";
import KeyPerformanceIndicator from "./key-performance-indicator";
import PropertyMap from "./property-map";
import { DemoPropertyModel } from "../api/cl-client";
import ActionListItem from "./action-item";
import SimpleActionListItem from "./simple-action-list-item"
import DemoSignupForm from "./demo-signup-hubspot-form";
import { GoogleAds } from "./google-ads";
import { thermalPerformanceGroups } from "../utils/thermalPerformance";
import { EpcEerGroups } from "../utils/epc";

interface AssetViewDemoPropertyProps {
    asset: DemoPropertyModel
}

function getTrend(delta: number | undefined) {
    if (delta === undefined) {
        return undefined
    } else if (Math.abs(delta) < 0.01) {
        return "flat"
    } else if (delta < 0) {
        return "down"
    } else {
        return "up"
    }
}

const DemoProperty: FC<AssetViewDemoPropertyProps> = (props) => {
    const theme = useTheme();

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const actionItems = useMemo(() => {
        // console.log(props.asset);
        return props.asset.packages?.map((pkg: any, index: number) => {
            if (props.asset.minimalBudget === false || (props.asset.minimalBudget && pkg.totalCostGbp < 5000)) {
                return (<ActionListItem 
                    asset={props.asset} 
                    action={pkg}
                    action_id={index} 
                    key={`action${index}`}
                    onContactClick={() => setOpen(true)}
                    onViewIncentiveClick={() => setOpen(true)}/>)
            } else {
                return null;
            }})
    }, [props.asset])

    return (
    <>
        <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
                <Stack spacing={3}>
                    <Stack component={Paper}>
                        <PropertyMap lng={props.asset.address.longitude} lat={props.asset.address.latitude} zoom={17} />
                        <TableContainer >
                            <Table size="small">
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Address:</TableCell>
                                        <TableCell>{props.asset.address.address1}<br />{props.asset.address.address2}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>City:</TableCell>
                                        <TableCell>{props.asset.address.city}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Postcode:</TableCell>
                                        <TableCell>{props.asset.address.postcode}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Built form:</TableCell>
                                        <TableCell>{props.asset.config.propertyType ? props.asset.config.propertyType : 'N/A'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Year built:</TableCell>
                                        <TableCell>{props.asset.config.yearBuilt ? props.asset.config.yearBuilt : 'N/A'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2} sx={{textAlign: 'center', fontWeight: 600, backgroundColor: theme.palette.grey[100]}}>EPC</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Energy Efficiency:</TableCell>
                                        <TableCell>{props.asset.epc ? props.asset.epc.currentEnergyEfficiencyRating : 'N/A'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Environmental Impact:</TableCell>
                                        <TableCell>{props.asset.epc ? props.asset.epc.currentEnvironmentalImpactRating : 'N/A'}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Stack>
                    <Paper style={{padding: '10px'}}>
                        <Typography variant="caption">
                            All estimates are based on synthetic energy data with an NMAE that is currently at 30%.
                        </Typography>
                    </Paper>
                    <GoogleAds />
                </Stack>
            </Grid>
            <Grid item xs={12} md={8} sx={{'> *': {paddingBottom: '1rem'}}}>
                <Typography variant="h4" sx={{color: theme.palette.primary.main, paddingBottom: '0.5em'}}>Energy profile</Typography>
                <Stack spacing={1} >
                    <Grid container justifyContent="space-between" direction="row" alignItems="stretch" spacing={1}> 
                        <Grid item xs={4} display="flex">
                            <Tooltip title="The CLEVR is the Energy Rating for your property based on your actual consumption. The measure is indicative and not an equivalent of the Energy Efficiency Rating reported on the EPC certificate.">
                                <KeyPerformanceIndicator name="CLEVR™" value={props.asset.stats?.virtualEpcEer} epc={EpcEerGroups} />
                            </Tooltip>
                        </Grid>
                        {props.asset.stats?.thermalPerformance && <Grid item xs={4} display="flex">
                            <Tooltip title="The Thermal Performance Indicator measures how much energy leaks from a property through the building fabric. A better rating score indicates better thermal efficiency, which can result in reduced energy consumption, lower bills, and a smaller carbon footprint.">
                                <KeyPerformanceIndicator name="Thermal Performance" value={props.asset.stats?.thermalPerformance} epc={thermalPerformanceGroups} unit="W/K" />
                            </Tooltip>
                        </Grid>}
                        <Grid item xs={4} display="flex">
                            <Tooltip title="The displayed temperature alignment score is an indication on how well the property alines with the national 1.5°C paris targets. Arrow next to the score indicates the 12 month trend for your property.">
                                <KeyPerformanceIndicator name="Temperature" value={Math.round(props.asset.stats?.temperatureAlignment * 10) / 10} trend={getTrend(props.asset.stats?.deltaTemperatureAlignment)} unit='°C' />
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Stack direction='row' justifyContent='stretch' spacing={1}>
                        <Grid item xs={12/3} display="flex">
                            <Tooltip title="Annual carbon emissions per square meter.">
                                <KeyPerformanceIndicator 
                                    name="Carbon Intensity" 
                                    value={(props.asset.stats?.carbonIntensity / 1000).toLocaleString('en-GB', {maximumSignificantDigits: 2})} 
                                    trend={getTrend(props.asset.stats?.deltaCarbonIntensity)} 
                                    unit='kgCO₂e / m² / year' 
                                    compact/>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12/3} display="flex">
                            <Tooltip title="Annual energy costs per square meter.">
                                <KeyPerformanceIndicator 
                                    name="Energy Cost Intensity" 
                                    value={(props.asset.stats?.energyCostGbp / props.asset.stats?.floorArea).toLocaleString('en-GB', {maximumSignificantDigits: 2})} 
                                    trend={getTrend(props.asset.stats?.deltaEnergyCostGbp)} 
                                    unit='£ / m² / year' 
                                    compact/>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12/3} display="flex">
                            <Tooltip title="Annual green house gas emissions.">
                                <KeyPerformanceIndicator 
                                    name="GHG emissions" 
                                    value={(props.asset.stats?.co2G / 1000).toLocaleString('en-GB', {maximumSignificantDigits: 2})} 
                                    trend={getTrend(props.asset.stats?.deltaCo2G / 1000)} 
                                    unit='kgCO₂e / year' 
                                    compact/>
                            </Tooltip>
                        </Grid>
                    </Stack>
                </Stack>

                <Typography variant="h4" sx={{color: theme.palette.primary.main, paddingBottom: '0.5em'}}>Immediate Actions</Typography>
                <Box>
                    <SimpleActionListItem 
                        assetId={props.asset._id as string}
                        name="Turn down the thermostat" 
                        description="18 degrees is enough for healthy adults, with slightly higher temperatures needed for the very old or young. So consider popping on a jumper at home and seeing what temperature your household is comfortable at."
                        unit="per °C"
                        energySavingsPercent={0.04} 
                        energySavingsGbp={props.asset.stats.energyCostGbp * 0.04} 
                        co2AvoidedPercent={props.asset.stats.gridGasCo2G * (props.asset.stats.energyCostGbp * 0.04) / props.asset.stats.gridGasCostGbp / props.asset.stats.co2G}/>
                    <SimpleActionListItem 
                        assetId={props.asset._id as string}
                        name="Set boiler temperature to 55 °C" 
                        description="Your boiler’s flow setting controls the temperature water is when it leaves your boiler. Its default setting is usually too high. Turn it down a bit and your home will feel just as warm, but you could cut your energy bills."
                        energySavingsPercent={props.asset.stats.gridGasCostGbp * 0.08 / props.asset.stats.energyCostGbp} 
                        energySavingsGbp={props.asset.stats.gridGasCostGbp * 0.08} 
                        co2AvoidedPercent={props.asset.stats.gridGasCo2G * 0.08 / props.asset.stats.co2G}/>
                    <SimpleActionListItem 
                        assetId={props.asset._id as string}
                        name="Turn off the lights" 
                        description="Develop the habit of turning off the lights when your are leaving a room."
                        energySavingsPercent={38 / props.asset.stats.energyCostGbp} 
                        energySavingsGbp={38} 
                        co2AvoidedPercent={props.asset.stats.gridElectricityCo2G * 38 / props.asset.stats.gridElectricityCostGbp / props.asset.stats.co2G}/>
                    <SimpleActionListItem 
                        assetId={props.asset._id as string}
                        name="Use LED lights" 
                        description="Lighting makes up around 11% of a typical power bill. LEDs use 70-80% less electricity."
                        unit="per bulb"
                        costGbp={3}
                        energySavingsPercent={9 / props.asset.stats.energyCostGbp} 
                        energySavingsGbp={9} 
                        co2AvoidedPercent={props.asset.stats.gridElectricityCo2G * 9 / props.asset.stats.gridElectricityCostGbp / props.asset.stats.co2G}/>
                </Box>
                <Typography variant="h4" sx={{color: theme.palette.primary.main, paddingBottom: '0.5em'}}>Actions</Typography>
                <Box>
                    {actionItems}
                    {props.asset.status !== 'done' ? <CircularProgress /> : null}
                </Box>  

                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth>
                    <DialogTitle id="form-dialog-title">Signup to save costs and increase value</DialogTitle>
                    <DialogContent>
                        <DemoSignupForm />
                    </DialogContent>
                </Dialog>
            </Grid>
        </Grid>
    </>)
}

export default DemoProperty;