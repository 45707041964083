import { FC } from 'react';

import Button from '@mui/material/Button';

interface SignOutButtonProps {
    href?: string;
    title?: string;
    icon?: React.ReactNode;
}

export const SignOutButton: FC<SignOutButtonProps> = (props) => {
    return (
        <Button
            color='inherit'
            href={props.href ? props.href : '/.auth/logout'}
        >
            { props.title ? props.title : 'Sign Out' }
        </Button>
    );
}