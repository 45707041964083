import { useState, useMemo, FC } from "react";

import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";

import { Client, lookupPostcode, PafAddress } from "@ideal-postcodes/core-axios";
import { Grid } from "@mui/material";
import { useAppContext } from "./app-context";

interface IPostcodeLookupProps {
    onAddressChanged: (address: PafAddress) => void;
}

const PostcodeLookup: FC<IPostcodeLookupProps> = (props) => {
    const [postcode, setPostcode] = useState("");
    const [addressList, setAddressList] = useState<PafAddress[]>([]);

    const appContext = useAppContext();

    const eventHandler = async () => {
        const client = new Client({ api_key: appContext.settings['REACT_APP_POSTCODE_LOOKUP_API_KEY'] as string});
        // const client = new Client({ api_key: "iddqd" });
        const lookup = await lookupPostcode({client, postcode: postcode});
        setAddressList(lookup);

        props.onAddressChanged(lookup[0]);
    };

    const addressItems = useMemo(() => addressList.map((address: any, index: number) => (
        <MenuItem key={index} value={index}>{address.line_1}</MenuItem>
    )), [addressList]);

    const onChange = (event: SelectChangeEvent<number>) => {
        props.onAddressChanged(addressList[event.target.value as number]);
    }

    return ( <>
        <Grid sx={{display: 'flex'}}>
            <TextField onChange={(e) => setPostcode(e.target.value)} sx={{flexGrow: 1}} label="Postcode" variant="standard" />
            <Button onClick={eventHandler}>Lookup</Button>
        </Grid>
        { addressItems.length ? <Select label="Address" variant="standard" onChange={onChange} defaultValue={0} >
            {addressItems}
        </Select> : null }
    </> );
}

export default PostcodeLookup;