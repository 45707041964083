import { Paper, Container, Typography } from '@mui/material';
import { FC } from 'react';
import Footer from '../../components/footer';


const Outcomes: FC = () => (
    <>
        <Container 
            component={Paper}
            sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                padding: '20px'
            }}>
            <Typography variant='h3'>DISCLAIMER</Typography>
            <Typography variant='caption'>Last updated: 2022-08-25</Typography>
            <Typography variant='h4'>WEBSITE DISCLAIMER</Typography>
            <Typography variant='body1'>The information provided by CarbonLaces (“Company”, “we”, “our”, “us”) on carbonlaces.com (the “Site”) is for general informational purposes only. All information on the Site is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site.</Typography>
            <Typography variant='body1'>UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE. YOUR USE OF THE SITE AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE IS SOLELY AT YOUR OWN RISK.</Typography>
            <Typography variant='h4'>EXTERNAL LINKS DISCLAIMER</Typography>
            <Typography variant='body1'>The Site may contain (or you may be sent through the Site) links to other websites or content belonging to or originating from third parties or links to websites and features. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability or completeness by us.</Typography>
            <Typography variant='body1'>For example, the outlined Disclaimer has been created using PolicyMaker.io, a free web application for generating high-quality legal documents. PolicyMaker’s disclaimer generator is an easy-to-use tool for creating an excellent sample Disclaimer template for a website, blog, eCommerce store or app.</Typography>
            <Typography variant='body1'>WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.</Typography>
            <Typography variant='h4'>PROFESSIONAL DISCLAIMER</Typography>
            <Typography variant='body1'>The Site can not and does not contain financial advice. The information is provided for general informational and educational purposes only and is not a substitute for professional financial advice. Accordingly, before taking any actions based upon such information, we encourage you to consult with the appropriate professionals. We do not provide any kind of financial advice.</Typography>
            <Typography variant='body1'>Content published on carbonlaces.com is intended to be used and must be used for informational purposes only. It is very important to do your own analysis before making any decision based on your own personal circumstances. You should take independent financial advice from a professional or independently research and verify any information that you find on our Website and wish to rely upon.</Typography>
            <Typography variant='body1'>THE USE OR RELIANCE OF ANY INFORMATION CONTAINED ON THIS SITE IS SOLELY AT YOUR OWN RISK.</Typography>
            <Typography variant='h4'>AFFILIATES DISCLAIMER</Typography>
            <Typography variant='body1'>The Site may contain links to affiliate websites, and we may receive an affiliate commission for any purchases or actions made by you on the affiliate websites using such links.</Typography>
            <Typography variant='h4'>TESTIMONIALS DISCLAIMER</Typography>
            <Typography variant='body1'>The Site may contain testimonials by users of our products and/or services. These testimonials reflect the real-life experiences and opinions of such users. However, the experiences are personal to those particular users, and may not necessarily be representative of all users of our products and/or services. We do not claim, and you should not assume that all users will have the same experiences.</Typography>
            <Typography variant='body1'>YOUR INDIVIDUAL RESULTS MAY VARY.</Typography>
            <Typography variant='body1'>The testimonials on the Site are submitted in various forms such as text, audio and/or video, and are reviewed by us before being posted. They appear on the Site verbatim as given by the users, except for the correction of grammar or typing errors. Some testimonials may have been shortened for the sake of brevity, where the full testimonial contained extraneous information not relevant to the general public.</Typography>
            <Typography variant='body1'>The views and opinions contained in the testimonials belong solely to the individual user and do not reflect our views and opinions.</Typography>
            <Typography variant='h4'>ERRORS AND OMISSIONS DISCLAIMER</Typography>
            <Typography variant='body1'>While we have made every attempt to ensure that the information contained in this site has been obtained from reliable sources, CarbonLaces is not responsible for any errors or omissions or for the results obtained from the use of this information. All information in this site is provided “as is”, with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information, and without warranty of any kind, express or implied, including, but not limited to warranties of performance, merchantability, and fitness for a particular purpose.</Typography>
            <Typography variant='body1'>In no event will CarbonLaces, its related partnerships or corporations, or the partners, agents or employees thereof be liable to you or anyone else for any decision made or action taken in reliance on the information in this Site or for any consequential, special or similar damages, even if advised of the possibility of such damages.</Typography>
            <Typography variant='h4'>GUEST CONTRIBUTORS DISCLAIMER</Typography>
            <Typography variant='body1'>This Site may include content from guest contributors and any views or opinions expressed in such posts are personal and do not represent those of CarbonLaces or any of its staff or affiliates unless explicitly stated.</Typography>
            <Typography variant='h4'>LOGOS AND TRADEMARKS DISCLAIMER</Typography>
            <Typography variant='body1'>All logos and trademarks of third parties referenced on carbonlaces.com are the trademarks and logos of their respective owners. Any inclusion of such trademarks or logos does not imply or constitute any approval, endorsement or sponsorship of CarbonLaces by such owners.</Typography>
            <Typography variant='h4'>CONTACT US</Typography>
            <Typography variant='body1'>Should you have any feedback, comments, requests for technical support or other inquiries, please contact us by email: <a href='mailto:madhuban@carbonlaces.com'>madhuban@carbonlaces.com</a>.</Typography>
        </Container>
        <Footer></Footer>
    </>
)

export default Outcomes;